/* src/lib/components/home/webgl/Video.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	component_subscribe,
	create_component,
	destroy_component,
	globals,
	init,
	listen,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "../../../../../_snowpack/pkg/svelte/internal.js";

const { window: window_1 } = globals;
import { get_camera, get_gl, get_parent } from './context.js';
import projects from '../../../../data/projects.js';
import vertex from '../../../shaders/vertex.js';
import fragment from '../../../shaders/image.js';

import {
	preloader,
	mouseSpeed,
	selectedProject,
	extension,
	scrollGlobalTween,
	slider,
	mouseDown,
	whiteNoise,
	click,
	entered
} from '../../../stores/index.js';

import { onDestroy, onMount } from '../../../../../_snowpack/pkg/svelte.js';
import Emitter from '../../../utils/Emitter.js';
import { Mesh, Plane, Program, Texture, Vec2 } from '../../../../../_snowpack/pkg/ogl.js';
import gsap from '../../../../../_snowpack/pkg/gsap.js';
import ProjectPlayer from './ProjectPlayer.svelte.js';
import ScrollTrigger from '../../../../../_snowpack/pkg/gsap/ScrollTrigger.js';

function create_fragment(ctx) {
	let projectplayer;
	let current;
	let mounted;
	let dispose;

	projectplayer = new ProjectPlayer({
			props: {
				visible: /*$selectedProject*/ ctx[1] === /*index*/ ctx[0],
				video: /*video*/ ctx[2],
				title: /*data*/ ctx[5].name,
				mouseDirection: /*mouseDirection*/ ctx[4],
				mouseDown: /*localMouseDown*/ ctx[3]
			}
		});

	projectplayer.$on("mousedown", /*onMouseDown*/ ctx[7]);
	projectplayer.$on("mouseup", /*onMouseUp*/ ctx[8]);
	projectplayer.$on("mousemove", /*onMouseMovePlayer*/ ctx[9]);

	return {
		c() {
			create_component(projectplayer.$$.fragment);
		},
		m(target, anchor) {
			mount_component(projectplayer, target, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(window_1, "resize", /*resize*/ ctx[10]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			const projectplayer_changes = {};
			if (dirty[0] & /*$selectedProject, index*/ 3) projectplayer_changes.visible = /*$selectedProject*/ ctx[1] === /*index*/ ctx[0];
			if (dirty[0] & /*video*/ 4) projectplayer_changes.video = /*video*/ ctx[2];
			if (dirty[0] & /*mouseDirection*/ 16) projectplayer_changes.mouseDirection = /*mouseDirection*/ ctx[4];
			if (dirty[0] & /*localMouseDown*/ 8) projectplayer_changes.mouseDown = /*localMouseDown*/ ctx[3];
			projectplayer.$set(projectplayer_changes);
		},
		i(local) {
			if (current) return;
			transition_in(projectplayer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(projectplayer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(projectplayer, detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $selectedProject;
	let $mouseSpeed;
	let $mouseDown;
	let $whiteNoise;
	let $click;
	let $gl;
	let $preloader;
	let $scrollGlobalTween;
	component_subscribe($$self, selectedProject, $$value => $$invalidate(1, $selectedProject = $$value));
	component_subscribe($$self, mouseSpeed, $$value => $$invalidate(32, $mouseSpeed = $$value));
	component_subscribe($$self, mouseDown, $$value => $$invalidate(33, $mouseDown = $$value));
	component_subscribe($$self, whiteNoise, $$value => $$invalidate(34, $whiteNoise = $$value));
	component_subscribe($$self, click, $$value => $$invalidate(35, $click = $$value));
	component_subscribe($$self, preloader, $$value => $$invalidate(37, $preloader = $$value));
	component_subscribe($$self, scrollGlobalTween, $$value => $$invalidate(38, $scrollGlobalTween = $$value));
	let { index } = $$props;
	let { element } = $$props;
	const data = projects[index];
	const gl = get_gl();
	component_subscribe($$self, gl, value => $$invalidate(36, $gl = value));
	const camera = get_camera();
	const parent = get_parent();
	const animValues = { vignette: 0, progress: 0 };
	const target = element.querySelector('.container');
	const img = element.querySelector('img');
	const loop = document.createElement('video');
	const video = document.createElement('video');
	video.autoplay = false;
	video.preload = 'metadata';
	video.controls = false;
	video.src = `/assets/videos/${data.video}.mp4`;
	loop.src = $preloader.getItemByUrl(`/assets/loops/${data.loop}.mp4`).blobUrl;
	loop.loop = true;
	loop.muted = true;
	loop.currentTime = video.currentTime = 0.001;
	let showLoop = false;
	let hovered = false;
	let mousePosition = { x: 0, y: 0 };
	let mouseProgress = 0;
	let intersecting = false;
	let localMouseDown = false;
	let mouseDirection = null;
	let mouseX = 0;

	onMount(() => {
		ScrollTrigger.create({
			trigger: target,
			containerAnimation: $scrollGlobalTween,
			id: 'Video' + index,
			start: 'left right',
			end: 'right left',
			horizontal: true,
			invalidateOnRefresh: true,
			onToggle(self) {
				intersecting = self.isActive;
				mesh.visible = self.isActive;
			}
		});

		ScrollTrigger.create({
			trigger: target,
			containerAnimation: $scrollGlobalTween,
			horizontal: true,
			start: 'left right-=45%',
			end: 'right left+=60%',
			id: `Project-${index}`,
			invalidateOnRefresh: true,
			onToggle(self) {
				if (self.isActive) slider.set(index);
			}
		});

		mesh.visible = false;
		target.addEventListener('mouseenter', onMouseEnter);
		target.addEventListener('mouseleave', onMouseLeave);
		target.addEventListener('mousemove', onMouseMove);

		loop.addEventListener('loadedmetadata', () => {
			loopTexture.image = loop;
			loopTexture.needsUpdate = true;
		});

		video.addEventListener('canplay', canPlayThrough);
		Emitter.on('render', render);

		if (index < 2) {
			mesh.visible = true;

			const anim = gsap.from(img, {
				xPercent: 200,
				duration: 1.25,
				delay: 0.75,
				force3D: true,
				immediateRender: true,
				ease: 'power3',
				onUpdate() {
					if (anim.progress() > 0.71) {
						entered.set(true);
					}

					setBounds();
				}
			});
		}

		return () => {
			Emitter.off('render', render);
		};
	});

	function canPlayThrough() {
		videoTexture.image = video;
		videoTexture.needsUpdate = true;
		video.removeEventListener('canplay', canPlayThrough);
	}

	let rect = img.getBoundingClientRect();

	let bounds = {
		left: rect.left,
		top: rect.top,
		width: rect.width,
		height: rect.height
	};

	let camUnit = { width: 1, height: 1 };

	const geometry = new Plane($gl,
	{
			width: 1,
			height: 1,
			heightSegments: 1,
			widthSegments: 1
		});

	const texture = new Texture($gl,
	{
			generateMipmaps: false,
			premultiplyAlpha: false
		});

	const noiseImage = new Image();
	noiseImage.src = $preloader.getItemByUrl(`/assets/noise.${extension}`).blobUrl;

	noiseImage.onload = function () {
		noiseTexture.image = noiseImage;
		noiseTexture.needsUpdate = true;
	};

	const disp1Image = new Image();
	disp1Image.src = $preloader.getItemByUrl(`/assets/disp1.${extension}`).blobUrl;

	disp1Image.onload = function () {
		disp1Texture.image = disp1Image;
		disp1Texture.needsUpdate = true;
	};

	const noiseTexture = new Texture($gl,
	{
			generateMipmaps: true,
			premultiplyAlpha: false,
			flipY: true,
			width: 256,
			height: 256,
			wrapS: $gl.REPEAT,
			wrapT: $gl.REPEAT
		});

	const disp1Texture = new Texture($gl,
	{
			generateMipmaps: true,
			premultiplyAlpha: false,
			flipY: true,
			width: 256,
			height: 256,
			wrapS: $gl.REPEAT,
			wrapT: $gl.REPEAT
		});

	const loopTexture = new Texture($gl,
	{
			generateMipmaps: false,
			width: 1920,
			height: 704
		});

	const videoTexture = new Texture($gl,
	{
			generateMipmaps: false,
			width: 1920,
			height: 1080
		});

	const program = new Program($gl,
	{
			vertex,
			fragment,
			uniforms: {
				tMap: { value: texture },
				tLoop: { value: loopTexture },
				tVideo: { value: videoTexture },
				tNoise: { value: noiseTexture },
				tDisplacement: { value: disp1Texture },
				uMeshSize: { value: new Vec2(1920, 1080) },
				uImageSize: { value: new Vec2(500, 281) },
				uTime: { value: 0 },
				uHovered: { value: false },
				uLoop: { value: false },
				uVignette: { value: 0 },
				uProgressHover: { value: 0 },
				uMouseSpeed: { value: 0 },
				uSelected: { value: false },
				uActive: { value: false },
				uProgress: { value: animValues.progress },
				uIndex: { value: index }
			},
			transparent: false
		});

	const mesh = new Mesh($gl, { geometry, program });
	mesh.setParent(parent);
	loadImage();

	function onMouseEnter(e) {
		$click.play();
		loopPlayHead(e);
		$$invalidate(13, hovered = true);
		$$invalidate(12, showLoop = true);

		gsap.to(animValues, {
			duration: 0.1,
			vignette: 1.0,
			onUpdate: () => {
				program.uniforms.uVignette.value = animValues.vignette;
			}
		});
	}

	function onMouseLeave() {
		const selected = $selectedProject === index;
		$$invalidate(13, hovered = false);

		gsap.to(animValues, {
			duration: 0.1,
			vignette: 0.0,
			onUpdate: () => {
				program.uniforms.uVignette.value = animValues.vignette;
			}
		});

		if (!selected) {
			$$invalidate(12, showLoop = false);
		}
	}

	function onMouseMove(e) {
		loopPlayHead(e);
	}

	function loopPlayHead(e) {
		const target = e.target;
		const rect = target.getBoundingClientRect();
		mousePosition.x = e.clientX - rect.left;
		mousePosition.y = e.clientY - rect.top;
		mouseProgress = mousePosition.x / rect.width;
		loop.currentTime = (mouseProgress || 0) * loop.duration;
	}

	function onMouseDown() {
		$$invalidate(3, localMouseDown = true);
		$$invalidate(4, mouseDirection = null);
		mouseDown.set(true);
		video.pause();
		$whiteNoise.rate(1);
		$whiteNoise.play();
	}

	function onMouseUp() {
		$$invalidate(3, localMouseDown = false);
		$$invalidate(4, mouseDirection = null);
		$whiteNoise.pause();
		mouseDown.set(false);
		video.play();
	}

	function onMouseMovePlayer(e) {
		if ($mouseDown) {
			$$invalidate(4, mouseDirection = mouseX - e.clientX < 0 ? 'forward' : 'rewind');
			mouseX = e.clientX;
			mouseProgress = e.clientX / window.innerWidth;
			$$invalidate(2, video.currentTime = mouseProgress * video.duration, video);
		}
	}

	function setBounds() {
		rect = img.getBoundingClientRect();

		bounds = {
			left: rect.left,
			top: rect.top,
			width: rect.width,
			height: rect.height
		};

		program.uniforms.uMeshSize.value.set(rect.width, rect.height);
		updateSize();
		updatePosition();
	}

	function updateSize() {
		camUnit = calculateUnitSize(camera.position.z - mesh.position.z);
		const x = bounds.width / window.innerWidth;
		const y = bounds.height / window.innerHeight;
		if (!x || !y) return;
		mesh.scale.x = camUnit.width * x;
		mesh.scale.y = camUnit.height * y;
	}

	function updatePosition() {
		updateY();
		updateX();
	}

	function updateX() {
		const { left } = bounds;
		mesh.position.x = -(camUnit.width / 2) + mesh.scale.x / 2;
		mesh.position.x += left / window.innerWidth * camUnit.width;
	}

	function updateY() {
		const { top } = bounds;
		mesh.position.y = camUnit.height / 2 - mesh.scale.y / 2;
		mesh.position.y -= top / window.innerHeight * camUnit.height;
	}

	function loadImage() {
		if (img.complete) {
			setImage();
		} else {
			img.onload = () => setImage();
		}
	}

	function setImage() {
		program.uniforms.uImageSize.value.set(img.naturalWidth, img.naturalHeight);
		texture.image = img;
		texture.needsUpdate = true;
	}

	function resize() {
		if (!mesh.visible) return;
		setBounds();
	}

	function render(t) {
		if (!intersecting) return;
		const selected = $selectedProject === index;
		program.uniforms.uMouseSpeed.value = $mouseSpeed;

		if (hovered) {
			loopTexture.needsUpdate = true;
		}

		if (selected) {
			videoTexture.needsUpdate = true;
		}

		setBounds();
		program.uniforms.uTime.value = t * 0.001;
	}

	onDestroy(() => {
		mesh.setParent(null);
		Emitter.off('render', render);
		target.removeEventListener('mouseenter', onMouseEnter);
		target.removeEventListener('mouseleave', onMouseLeave);
		target.removeEventListener('mousemove', onMouseMove);
	});

	function handleHoverChanged(value) {
		program.uniforms.uHovered.value = value;
	}

	function handleShowLoop(value) {
		program.uniforms.uLoop.value = value;

		gsap.to(program.uniforms.uProgressHover, {
			value: value ? 1.0 : 0.0,
			duration: 0.25,
			ease: 'power3'
		});
	}

	function handleSelected(selectedIndex) {
		const isSelected = selectedIndex === index;
		program.uniforms.uSelected.value = isSelected;

		if (isSelected) {
			$$invalidate(2, video.currentTime = 0, video);
			video.play();
		} else {
			$$invalidate(13, hovered = false);
			video.pause();
			$$invalidate(12, showLoop = false);
			handleShowLoop(false);
		}

		gsap.to(animValues, {
			duration: 0.55,
			ease: 'power3',
			progress: isSelected ? 1 : 0,
			onUpdate: () => {
				program.uniforms.uProgress.value = animValues.progress;
			}
		});
	}

	function calculateUnitSize(distance = mesh.position.z) {
		const vFov = camera.fov * Math.PI / 180;
		const height = 2 * Math.tan(vFov / 2) * distance;
		const width = height * camera.aspect;
		return { width, height };
	}

	$$self.$$set = $$props => {
		if ('index' in $$props) $$invalidate(0, index = $$props.index);
		if ('element' in $$props) $$invalidate(11, element = $$props.element);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*hovered*/ 8192) {
			$: handleHoverChanged(hovered);
		}

		if ($$self.$$.dirty[0] & /*showLoop*/ 4096) {
			$: handleShowLoop(showLoop);
		}

		if ($$self.$$.dirty[0] & /*$selectedProject*/ 2) {
			$: handleSelected($selectedProject);
		}
	};

	return [
		index,
		$selectedProject,
		video,
		localMouseDown,
		mouseDirection,
		data,
		gl,
		onMouseDown,
		onMouseUp,
		onMouseMovePlayer,
		resize,
		element,
		showLoop,
		hovered
	];
}

class Video extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { index: 0, element: 11 }, null, [-1, -1]);
	}
}

export default Video;