/* src/lib/components/home/webgl/CameraGL.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	component_subscribe,
	create_slot,
	get_all_dirty_from_scope,
	get_slot_changes,
	globals,
	init,
	listen,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot_base
} from "../../../../../_snowpack/pkg/svelte/internal.js";

const { window: window_1 } = globals;
import { get_gl, set_camera } from './context.js';
import { Camera } from '../../../../../_snowpack/pkg/ogl.js';

function create_fragment(ctx) {
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[3].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[2], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;

			if (!mounted) {
				dispose = listen(window_1, "resize-", /*resize*/ ctx[1]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 4)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[2],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[2])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[2], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $gl;
	let { $$slots: slots = {}, $$scope } = $$props;
	const gl = get_gl();
	component_subscribe($$self, gl, value => $$invalidate(4, $gl = value));

	const camera = new Camera($gl,
	{
			fov: 45,
			aspect: window.innerWidth / window.innerHeight,
			near: 1,
			far: 1000
		});

	camera.position.set(0, 0, 100);
	set_camera(camera);

	function resize() {
		camera.updateMatrixWorld();
	}

	$$self.$$set = $$props => {
		if ('$$scope' in $$props) $$invalidate(2, $$scope = $$props.$$scope);
	};

	return [gl, resize, $$scope, slots];
}

class CameraGL extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default CameraGL;