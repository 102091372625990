/* src/lib/components/home/webgl/PostProcessing.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	component_subscribe,
	create_slot,
	get_all_dirty_from_scope,
	get_slot_changes,
	globals,
	init,
	listen,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot_base
} from "../../../../../_snowpack/pkg/svelte/internal.js";

const { window: window_1 } = globals;
import { get_camera, get_gl, get_scene } from './context.js';
import { Post, Texture, Vec2 } from '../../../../../_snowpack/pkg/ogl.js';
import rgbShift from '../../../shaders/rgb_shift.js';
import { onDestroy, onMount } from '../../../../../_snowpack/pkg/svelte.js';
import Emitter from '../../../utils/Emitter.js';
import gsap from '../../../../../_snowpack/pkg/gsap.js';

import {
	extension,
	mouseSpeed,
	preloader,
	speed,
	mouseDown,
	videoPaused,
	whiteNoise
} from '../../../stores/index.js';

function create_fragment(ctx) {
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[4].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[3], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;

			if (!mounted) {
				dispose = listen(window_1, "resize", /*resize*/ ctx[1]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[3], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $videoPaused;
	let $speed;
	let $mouseSpeed;
	let $mouseDown;
	let $whiteNoise;
	let $gl;
	let $preloader;
	component_subscribe($$self, videoPaused, $$value => $$invalidate(2, $videoPaused = $$value));
	component_subscribe($$self, speed, $$value => $$invalidate(9, $speed = $$value));
	component_subscribe($$self, mouseSpeed, $$value => $$invalidate(10, $mouseSpeed = $$value));
	component_subscribe($$self, mouseDown, $$value => $$invalidate(11, $mouseDown = $$value));
	component_subscribe($$self, whiteNoise, $$value => $$invalidate(12, $whiteNoise = $$value));
	component_subscribe($$self, preloader, $$value => $$invalidate(14, $preloader = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	const gl = get_gl();
	component_subscribe($$self, gl, value => $$invalidate(13, $gl = value));
	const camera = get_camera();
	const scene = get_scene();

	const noiseTexture = new Texture($gl,
	{
			generateMipmaps: true,
			premultiplyAlpha: false,
			flipY: true,
			width: 256,
			height: 256,
			wrapS: $gl.REPEAT,
			wrapT: $gl.REPEAT
		});

	const noiseImage = new Image();
	noiseImage.src = $preloader.getItemByUrl(`/assets/noise.${extension}`).blobUrl;

	noiseImage.onload = function () {
		noiseTexture.image = noiseImage;
		noiseTexture.needsUpdate = true;
	};

	const resolution = new Vec2(window.innerWidth, window.innerHeight);
	const composite = new Post($gl);

	let compositePass = composite.addPass({
		fragment: rgbShift,
		uniforms: {
			uOffset: { value: new Vec2(0, 0) },
			uResolution: { value: resolution },
			uTime: { value: 0 },
			uMouseSpeed: { value: 0 },
			tNoise: { value: noiseTexture },
			uMouseDown: { value: false },
			uPaused: { value: false }
		}
	});

	onMount(() => {
		Emitter.on('render', render);
	});

	function render(t) {
		const rate = gsap.utils.mapRange(0, 5, 1, 2.5, $mouseSpeed);
		$whiteNoise.rate(rate);
		compositePass.uniforms.uMouseDown.value = $mouseDown;
		compositePass.uniforms.uMouseSpeed.value = $mouseSpeed;
		compositePass.uniforms.uTime.value = t * 0.001;
		compositePass.uniforms.uOffset.value.set($speed, 0);
		composite.enabled = false;
		composite.targetOnly = true;
		composite.render({ scene, camera });
		composite.enabled = true;
		composite.targetOnly = false;
		compositePass.uniforms.uOffset.value.set(0, 0);
		composite.render({ texture: composite.uniform.value });
	}

	function resize() {
		const { innerWidth: width, innerHeight: height } = window;
		composite.resize();
		compositePass.uniforms.uResolution.value.set(width, height);
	}

	onDestroy(() => {
		Emitter.off('render', render);
	});

	function handlePaused(paused) {
		compositePass.uniforms.uPaused.value = paused;
	}

	$$self.$$set = $$props => {
		if ('$$scope' in $$props) $$invalidate(3, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$videoPaused*/ 4) {
			$: handlePaused($videoPaused);
		}
	};

	return [gl, resize, $videoPaused, $$scope, slots];
}

class PostProcessing extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default PostProcessing;