import './Background.svelte.css';
/* src/lib/components/Background.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space,
	src_url_equal
} from "../../../_snowpack/pkg/svelte/internal.js";

import gsap from '../../../_snowpack/pkg/gsap.js';
import { createEventDispatcher, onMount } from '../../../_snowpack/pkg/svelte.js';
import { color, preloaderLoaded } from '../stores/index.js';

function create_fragment(ctx) {
	let div2;
	let div0;
	let video0;
	let video0_src_value;
	let t;
	let div1;
	let video1;
	let video1_src_value;

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			video0 = element("video");
			t = space();
			div1 = element("div");
			video1 = element("video");
			if (!src_url_equal(video0.src, video0_src_value = "/assets/loader/" + /*$color*/ ctx[2] + "/R.mp4")) attr(video0, "src", video0_src_value);
			attr(video0, "preload", "auto");
			video0.playsInline = true;
			video0.muted = true;
			attr(video0, "class", "svelte-1p6ku5q");
			attr(div0, "class", "r svelte-1p6ku5q");
			if (!src_url_equal(video1.src, video1_src_value = "/assets/loader/" + /*$color*/ ctx[2] + "/B.mp4")) attr(video1, "src", video1_src_value);
			attr(video1, "preload", "auto");
			video1.playsInline = true;
			video1.muted = true;
			attr(video1, "class", "svelte-1p6ku5q");
			attr(div1, "class", "b svelte-1p6ku5q");
			attr(div2, "class", "background svelte-1p6ku5q");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, video0);
			/*video0_binding*/ ctx[4](video0);
			append(div2, t);
			append(div2, div1);
			append(div1, video1);
			/*video1_binding*/ ctx[5](video1);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$color*/ 4 && !src_url_equal(video0.src, video0_src_value = "/assets/loader/" + /*$color*/ ctx[2] + "/R.mp4")) {
				attr(video0, "src", video0_src_value);
			}

			if (dirty & /*$color*/ 4 && !src_url_equal(video1.src, video1_src_value = "/assets/loader/" + /*$color*/ ctx[2] + "/B.mp4")) {
				attr(video1, "src", video1_src_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			/*video0_binding*/ ctx[4](null);
			/*video1_binding*/ ctx[5](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $color;
	component_subscribe($$self, color, $$value => $$invalidate(2, $color = $$value));
	const dispatch = createEventDispatcher();
	let { progress = 0 } = $$props;
	let count = 0;
	let r;
	let b;
	let anim;

	onMount(() => {
		r.addEventListener('canplay', videoLoaded);
		b.addEventListener('canplay', videoLoaded);

		anim = gsap.to(['.r', '.b'], {
			scaleY: 1,
			transformOrigin: 'center center',
			ease: 'expo',
			duration: 2,
			paused: true
		});
	});

	function videoLoaded() {
		count++;

		if (count === 2) {
			r.removeEventListener('canplay', videoLoaded);
			b.removeEventListener('canplay', videoLoaded);
			preloaderLoaded.set(true);
		}
	}

	function handleProgress(p) {
		if (p === 0) return;

		if (p === 100) {
			gsap.delayedCall(0.2, () => {
				dispatch('complete');
			});

			gsap.to(anim, {
				progress: p / 100,
				ease: 'linear',
				duration: 0.6,
				overwrite: true,
				onComplete() {
					b.play();
					r.play();
				}
			});
		} else {
			gsap.to(anim, {
				progress: p / 100,
				ease: 'linear',
				duration: 0.25,
				overwrite: true
			});
		}
	}

	function video0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			r = $$value;
			$$invalidate(0, r);
		});
	}

	function video1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			b = $$value;
			$$invalidate(1, b);
		});
	}

	$$self.$$set = $$props => {
		if ('progress' in $$props) $$invalidate(3, progress = $$props.progress);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*progress*/ 8) {
			$: handleProgress(progress);
		}
	};

	return [r, b, $color, progress, video0_binding, video1_binding];
}

class Background extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { progress: 3 });
	}
}

export default Background;