import './Scroller.svelte.css';
/* src/lib/components/home/Scroller.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	space,
	toggle_class,
	transition_in,
	transition_out
} from "../../../../_snowpack/pkg/svelte/internal.js";

import gsap from '../../../../_snowpack/pkg/gsap.js';
import { Draggable } from '../../../../_snowpack/pkg/gsap/Draggable.js';
import { ScrollTrigger } from '../../../../_snowpack/pkg/gsap/ScrollTrigger.js';
import { ScrollToPlugin } from '../../../../_snowpack/pkg/gsap/ScrollToPlugin.js';
import { InertiaPlugin } from '../../../../_snowpack/pkg/gsap/InertiaPlugin.js';
import ScrollSmoother from '../../../../_snowpack/pkg/gsap/ScrollSmoother.js';
import CustomEase from '../../../../_snowpack/pkg/gsap/CustomEase.js';

import {
	scrollGlobalTween,
	scrollSmoother,
	blinking,
	speed,
	entered
} from '../../stores/index.js';

import { onMount } from '../../../../_snowpack/pkg/svelte.js';
import About from '../about/About.svelte.js';
import Projects from './Projects.svelte.js';
import UI from './UI.svelte.js';
import WebGL from './WebGL.svelte.js';

function create_if_block(ctx) {
	let ui;
	let current;

	ui = new UI({
			props: { value: /*projectProgress*/ ctx[1] }
		});

	return {
		c() {
			create_component(ui.$$.fragment);
		},
		m(target, anchor) {
			mount_component(ui, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const ui_changes = {};
			if (dirty & /*projectProgress*/ 2) ui_changes.value = /*projectProgress*/ ctx[1];
			ui.$set(ui_changes);
		},
		i(local) {
			if (current) return;
			transition_in(ui.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(ui.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(ui, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let div1;
	let t0;
	let div0;
	let projects_1;
	let t1;
	let webgl;
	let t2;
	let about;
	let t3;
	let div3;
	let current;
	let mounted;
	let dispose;
	let if_block = /*$entered*/ ctx[2] && create_if_block(ctx);
	projects_1 = new Projects({});
	webgl = new WebGL({});
	about = new About({});

	return {
		c() {
			div2 = element("div");
			div1 = element("div");
			if (if_block) if_block.c();
			t0 = space();
			div0 = element("div");
			create_component(projects_1.$$.fragment);
			t1 = space();
			create_component(webgl.$$.fragment);
			t2 = space();
			create_component(about.$$.fragment);
			t3 = space();
			div3 = element("div");
			attr(div0, "class", "projects-container svelte-1n1rhhz");
			attr(div2, "class", "slider svelte-1n1rhhz");
			attr(div3, "class", "about-button svelte-1n1rhhz");
			toggle_class(div3, "recording", /*$blinking*/ ctx[3]);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div1);
			if (if_block) if_block.m(div1, null);
			append(div1, t0);
			append(div1, div0);
			mount_component(projects_1, div0, null);
			append(div1, t1);
			mount_component(webgl, div1, null);
			append(div1, t2);
			mount_component(about, div1, null);
			/*div1_binding*/ ctx[5](div1);
			insert(target, t3, anchor);
			insert(target, div3, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(div3, "click", /*onClickAbout*/ ctx[4]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*$entered*/ ctx[2]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$entered*/ 4) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div1, t0);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			if (!current || dirty & /*$blinking*/ 8) {
				toggle_class(div3, "recording", /*$blinking*/ ctx[3]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			transition_in(projects_1.$$.fragment, local);
			transition_in(webgl.$$.fragment, local);
			transition_in(about.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			transition_out(projects_1.$$.fragment, local);
			transition_out(webgl.$$.fragment, local);
			transition_out(about.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			if (if_block) if_block.d();
			destroy_component(projects_1);
			destroy_component(webgl);
			destroy_component(about);
			/*div1_binding*/ ctx[5](null);
			if (detaching) detach(t3);
			if (detaching) detach(div3);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $entered;
	let $blinking;
	component_subscribe($$self, entered, $$value => $$invalidate(2, $entered = $$value));
	component_subscribe($$self, blinking, $$value => $$invalidate(3, $blinking = $$value));
	gsap.registerPlugin(Draggable, ScrollToPlugin, ScrollTrigger, InertiaPlugin, ScrollSmoother, CustomEase);
	let container;
	let scrollContainer;
	let projects;
	let projectProgress = 0;
	let aboutOpened = false;

	onMount(() => {
		const proxy = document.createElement('div');
		let projectsEl = document.querySelector('.fake-videos');
		scrollContainer = document.querySelector('.projects');
		projects = document.querySelectorAll('.project');

		scrollSmoother.set(ScrollSmoother.create({
			smooth: 1.1,
			effects: false,
			normalizeScroll: true,
			content: '#content',
			wrapper: '#viewport'
		}));

		let scrollTween = gsap.to(projects, {
			xPercent: -100 * (projects.length - 1),
			duration: 1,
			ease: 'none',
			force3D: true,
			paused: true,
			scrollTrigger: {
				trigger: '.fake-videos',
				scrub: true,
				id: 'videos',
				start: 'top top',
				end: 'bottom bottom',
				invalidateOnRefresh: true,
				onUpdate: self => {
					speed.set(gsap.utils.clamp(-0.01, 0.01, self.getVelocity() * 0.00001));
				},
				onLeave() {
					speed.set(0);
				}
			},
			onUpdate: () => {
				$$invalidate(1, projectProgress = scrollTween.progress());
			}
		});

		scrollGlobalTween.set(scrollTween);

		let aboutTween = gsap.timeline({
			paused: true,
			scrollTrigger: {
				trigger: '.fake-about',
				scrub: true,
				id: 'about',
				start: 'top bottom',
				end: 'bottom bottom+=1px',
				invalidateOnRefresh: true,
				onLeaveBack() {
					aboutOpened = false;
				}
			}
		});

		aboutTween.to(
			'#about-container',
			{
				duration: 1,
				clipPath() {
					const size = 40.5 / 1440 * 100;
					return `circle(150% at calc(100% - ${size}vw) ${size}vw)`;
				},
				force3D: true,
				ease: 'linear'
			},
			0
		);

		aboutTween.to(
			'.about-button',
			{
				duration: 0.05,
				background: 'white',
				force3D: true,
				ease: 'none'
			},
			0
		);

		gsap.to('#about-content', {
			duration: 1,
			force3D: true,
			y: () => {
				return -(document.getElementById('about-content').offsetHeight - window.innerHeight);
			},
			ease: 'none',
			scrollTrigger: {
				trigger: '.fake-about-scroll',
				scrub: true,
				id: 'about-scroll',
				start: 'top bottom+=1px',
				end: 'bottom bottom',
				invalidateOnRefresh: true,
				onEnter() {
					aboutOpened = true;
				}
			}
		});

		let dragRatio = projectsEl.offsetHeight / (window.innerWidth * (projects.length - 1)) * 1.5;

		let drag = Draggable.create(proxy, {
			//trigger: document.body,
			trigger: scrollContainer,
			inertia: true,
			throwResistance: 1000,
			maxDuration: 0.75,
			type: 'x',
			onPress() {
				this.startScroll = window.scrollY;
			},
			onDrag() {
				const y = this.startScroll - (this.x - this.startX) * dragRatio;
				ScrollSmoother.get().scrollTo(y, true);
			},
			onThrowUpdate() {
				const y = this.startScroll - (this.x - this.startX) * dragRatio;
				ScrollSmoother.get().scrollTo(y, true);
			}
		});

		return () => {
			scrollTween.kill();
			aboutTween.kill();
			scrollTween = aboutTween = null;
			drag[0].kill();
			drag = null;
		};
	});

	function onClickAbout() {
		if (aboutOpened) {
			gsap.to(window, {
				duration: 0.25,
				ease: 'expo',
				scrollTo: {
					y: '.fake-about',
					offsetY: window.innerHeight + 10,
					autoKill: true
				},
				overwrite: true
			});
		} else {
			gsap.to(window, {
				duration: 0.25,
				ease: 'expo',
				scrollTo: {
					y: '.fake-about',
					offsetY: window.innerHeight * 0.5,
					autoKill: true
				},
				overwrite: true
			});
		}
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			container = $$value;
			$$invalidate(0, container);
		});
	}

	return [container, projectProgress, $entered, $blinking, onClickAbout, div1_binding];
}

class Scroller extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Scroller;