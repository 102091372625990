import './ProjectDescription.svelte.css';
/* src/lib/components/home/ProjectDescription.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	text as text_1
} from "../../../../_snowpack/pkg/svelte/internal.js";

import { onMount } from '../../../../_snowpack/pkg/svelte.js';
import gsap from '../../../../_snowpack/pkg/gsap.js';
import { SplitText } from '../../../../_snowpack/pkg/gsap/SplitText.js';

function create_fragment(ctx) {
	let div;
	let p;
	let t;

	return {
		c() {
			div = element("div");
			p = element("p");
			t = text_1(/*description*/ ctx[0]);
			attr(p, "data-anim", "");
			attr(p, "class", "svelte-1rumr7e");
			attr(div, "class", "description-item svelte-1rumr7e");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, p);
			append(p, t);
			/*p_binding*/ ctx[4](p);
			/*div_binding*/ ctx[5](div);
		},
		p(ctx, [dirty]) {
			if (dirty & /*description*/ 1) set_data(t, /*description*/ ctx[0]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			/*p_binding*/ ctx[4](null);
			/*div_binding*/ ctx[5](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	gsap.registerPlugin(SplitText);
	let { description } = $$props;
	let { visible } = $$props;
	let el;
	let text;
	let splitText;

	onMount(() => {
		splitText = new SplitText(text, { linesClass: 'anim-text' });
		let fakeSplit = new SplitText(text, { linesClass: 'name-line' });
		handleVisible(visible);

		return () => {
			splitText.revert();
			fakeSplit.revert();
			splitText = null;
			fakeSplit = null;
		};
	});

	function handleVisible(isVisible) {
		isVisible ? animateIn() : animateOut();
	}

	function animateIn() {
		if (!splitText) return;
		gsap.set(el, { autoAlpha: 1 });

		gsap.fromTo(splitText.lines, { yPercent: 140 }, {
			yPercent: 0,
			ease: 'power4.out',
			stagger: 0.1,
			duration: 0.9,
			delay: 0.15,
			force3D: true,
			overwrite: true
		});
	}

	function animateOut() {
		if (!splitText) return;

		gsap.to(splitText.lines, {
			yPercent: -130,
			ease: 'power4.out',
			stagger: -0.1,
			duration: 0.45,
			force3D: true,
			overwrite: true,
			onComplete() {
				gsap.set(el, { autoAlpha: 0 });
			}
		});
	}

	function p_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			text = $$value;
			$$invalidate(2, text);
		});
	}

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(1, el);
		});
	}

	$$self.$$set = $$props => {
		if ('description' in $$props) $$invalidate(0, description = $$props.description);
		if ('visible' in $$props) $$invalidate(3, visible = $$props.visible);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*visible*/ 8) {
			$: handleVisible(visible);
		}
	};

	return [description, el, text, visible, p_binding, div_binding];
}

class ProjectDescription extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { description: 0, visible: 3 });
	}
}

export default ProjectDescription;