/* src/lib/components/home/UI.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../../../_snowpack/pkg/svelte/internal.js";

import Progress from './Progress.svelte.js';
import PlayHead from './PlayHead.svelte.js';
import Info from './Info.svelte.js';

function create_fragment(ctx) {
	let playhead;
	let t0;
	let progress;
	let t1;
	let info;
	let current;
	playhead = new PlayHead({ props: { value: /*value*/ ctx[0] } });
	progress = new Progress({ props: { value: /*value*/ ctx[0] } });
	info = new Info({});

	return {
		c() {
			create_component(playhead.$$.fragment);
			t0 = space();
			create_component(progress.$$.fragment);
			t1 = space();
			create_component(info.$$.fragment);
		},
		m(target, anchor) {
			mount_component(playhead, target, anchor);
			insert(target, t0, anchor);
			mount_component(progress, target, anchor);
			insert(target, t1, anchor);
			mount_component(info, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const playhead_changes = {};
			if (dirty & /*value*/ 1) playhead_changes.value = /*value*/ ctx[0];
			playhead.$set(playhead_changes);
			const progress_changes = {};
			if (dirty & /*value*/ 1) progress_changes.value = /*value*/ ctx[0];
			progress.$set(progress_changes);
		},
		i(local) {
			if (current) return;
			transition_in(playhead.$$.fragment, local);
			transition_in(progress.$$.fragment, local);
			transition_in(info.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(playhead.$$.fragment, local);
			transition_out(progress.$$.fragment, local);
			transition_out(info.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(playhead, detaching);
			if (detaching) detach(t0);
			destroy_component(progress, detaching);
			if (detaching) detach(t1);
			destroy_component(info, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { value } = $$props;

	$$self.$$set = $$props => {
		if ('value' in $$props) $$invalidate(0, value = $$props.value);
	};

	return [value];
}

class UI extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { value: 0 });
	}
}

export default UI;