import './PlayHead.svelte.css';
/* src/lib/components/home/PlayHead.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	component_subscribe,
	create_in_transition,
	detach,
	element,
	globals,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	svg_element,
	text,
	toggle_class
} from "../../../../_snowpack/pkg/svelte/internal.js";

const { window: window_1 } = globals;
import projects from '../../../data/projects.js';
import { slider } from '../../stores/index.js';
import { onMount } from '../../../../_snowpack/pkg/svelte.js';
import { fly } from '../../../../_snowpack/pkg/svelte/transition.js';
import { expoOut } from '../../../../_snowpack/pkg/svelte/easing.js';
import gsap from '../../../../_snowpack/pkg/gsap.js';

function create_fragment(ctx) {
	let div2;
	let div1;
	let svg0;
	let g1;
	let g0;
	let path0;
	let t0;
	let svg1;
	let g3;
	let g2;
	let path1;
	let t1;
	let div0;
	let p0;
	let t3;
	let p1;
	let t4;
	let t5;
	let t6;
	let t7;
	let t8;
	let div2_intro;
	let mounted;
	let dispose;

	return {
		c() {
			div2 = element("div");
			div1 = element("div");
			svg0 = svg_element("svg");
			g1 = svg_element("g");
			g0 = svg_element("g");
			path0 = svg_element("path");
			t0 = space();
			svg1 = svg_element("svg");
			g3 = svg_element("g");
			g2 = svg_element("g");
			path1 = svg_element("path");
			t1 = space();
			div0 = element("div");
			p0 = element("p");
			p0.textContent = "Date:";
			t3 = space();
			p1 = element("p");
			t4 = text(/*day*/ ctx[3]);
			t5 = text(":");
			t6 = text(/*month*/ ctx[4]);
			t7 = text(":");
			t8 = text(/*year*/ ctx[5]);
			attr(path0, "d", "M5,10v0l5,-10v0h-10v0z");
			attr(path0, "fill", "#106dff");
			attr(path0, "fill-opacity", "1");
			attr(path0, "class", "svelte-qfy05n");
			attr(svg0, "class", "top-arrow svelte-qfy05n");
			attr(svg0, "width", /*size*/ ctx[2]);
			attr(svg0, "height", /*size*/ ctx[2]);
			attr(svg0, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg0, "viewBox", "0 0 10 10");
			attr(path1, "d", "M5,0v0l5,10v0h-10v0z");
			attr(path1, "fill", "#106dff");
			attr(path1, "fill-opacity", "1");
			attr(path1, "class", "svelte-qfy05n");
			attr(svg1, "class", "bottom-arrow svelte-qfy05n");
			attr(svg1, "width", /*size*/ ctx[2]);
			attr(svg1, "height", /*size*/ ctx[2]);
			attr(svg1, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg1, "viewBox", "0 0 10 10");
			attr(p0, "class", "svelte-qfy05n");
			attr(p1, "class", "svelte-qfy05n");
			attr(div0, "class", "date svelte-qfy05n");
			toggle_class(div0, "rightSided", /*rightSided*/ ctx[1]);
			attr(div1, "class", "progress svelte-qfy05n");
			attr(div1, "id", "play-head");
			set_style(div1, "transform", "translateX(" + /*value*/ ctx[0] * 100 + "vw)");
			attr(div2, "class", "play-head svelte-qfy05n");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div1);
			append(div1, svg0);
			append(svg0, g1);
			append(g1, g0);
			append(g0, path0);
			append(div1, t0);
			append(div1, svg1);
			append(svg1, g3);
			append(g3, g2);
			append(g2, path1);
			append(div1, t1);
			append(div1, div0);
			append(div0, p0);
			append(div0, t3);
			append(div0, p1);
			append(p1, t4);
			append(p1, t5);
			append(p1, t6);
			append(p1, t7);
			append(p1, t8);

			if (!mounted) {
				dispose = listen(window_1, "resize", /*onResize*/ ctx[6]);
				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			if (dirty & /*size*/ 4) {
				attr(svg0, "width", /*size*/ ctx[2]);
			}

			if (dirty & /*size*/ 4) {
				attr(svg0, "height", /*size*/ ctx[2]);
			}

			if (dirty & /*size*/ 4) {
				attr(svg1, "width", /*size*/ ctx[2]);
			}

			if (dirty & /*size*/ 4) {
				attr(svg1, "height", /*size*/ ctx[2]);
			}

			if (dirty & /*day*/ 8) set_data(t4, /*day*/ ctx[3]);
			if (dirty & /*month*/ 16) set_data(t6, /*month*/ ctx[4]);
			if (dirty & /*year*/ 32) set_data(t8, /*year*/ ctx[5]);

			if (dirty & /*rightSided*/ 2) {
				toggle_class(div0, "rightSided", /*rightSided*/ ctx[1]);
			}

			if (dirty & /*value*/ 1) {
				set_style(div1, "transform", "translateX(" + /*value*/ ctx[0] * 100 + "vw)");
			}
		},
		i(local) {
			if (!div2_intro) {
				add_render_callback(() => {
					div2_intro = create_in_transition(div2, fly, {
						delay: 0,
						duration: 750,
						x: -300,
						y: 0,
						opacity: 1,
						easing: expoOut
					});

					div2_intro.start();
				});
			}
		},
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			mounted = false;
			dispose();
		}
	};
}

const threshold = 0.75;

function padNumber(value) {
	let p = value.toString();
	if (p.length < 2) p = '0' + p;
	return p;
}

function instance($$self, $$props, $$invalidate) {
	let $slider;
	component_subscribe($$self, slider, $$value => $$invalidate(7, $slider = $$value));
	let { value } = $$props;
	let rightSided = true;
	let size = 10;

	let data = {
		day: projects[0].day,
		month: projects[0].month,
		year: projects[0].year
	};

	let day = padNumber(data.day);
	let month = padNumber(data.month);
	let year = data.year;

	onMount(() => {
		onResize();
	});

	function watchValue(value) {
		$$invalidate(1, rightSided = value < threshold);
	}

	function onResize() {
		$$invalidate(2, size = 10 / 1440 * window.innerWidth);
	}

	function handleSliderChange(slider) {
		gsap.to(data, {
			day: projects[slider].day,
			month: projects[slider].month,
			year: projects[slider].year,
			duration: 0.35,
			ease: 'linear',
			roundProps: 'day,month,year',
			onUpdate() {
				$$invalidate(3, day = padNumber(data.day));
				$$invalidate(4, month = padNumber(data.month));
				$$invalidate(5, year = data.year);
			}
		});
	}

	$$self.$$set = $$props => {
		if ('value' in $$props) $$invalidate(0, value = $$props.value);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*value*/ 1) {
			$: watchValue(value);
		}

		if ($$self.$$.dirty & /*$slider*/ 128) {
			$: handleSliderChange($slider);
		}
	};

	return [value, rightSided, size, day, month, year, onResize, $slider];
}

class PlayHead extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { value: 0 });
	}
}

export default PlayHead;