/* src/lib/components/home/webgl/SceneGL.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	component_subscribe,
	create_slot,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot_base
} from "../../../../../_snowpack/pkg/svelte/internal.js";

import { Transform } from '../../../../../_snowpack/pkg/ogl.js';
import { get_camera, get_renderer, set_parent, set_scene } from './context.js';
import { onDestroy, onMount } from '../../../../../_snowpack/pkg/svelte.js';
import Emitter from '../../../utils/Emitter.js';

function create_fragment(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[3].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[2], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 4)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[2],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[2])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[2], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $renderer;
	let { $$slots: slots = {}, $$scope } = $$props;
	let { render = true } = $$props;
	const scene = new Transform();
	const camera = get_camera();
	const renderer = get_renderer();
	component_subscribe($$self, renderer, value => $$invalidate(4, $renderer = value));
	set_parent(scene);
	set_scene(scene);

	onMount(() => {
		if (render) Emitter.on('render', do_render);
	});

	function do_render() {
		$renderer.render({ scene, camera });
	}

	onDestroy(() => {
		Emitter.off('render', do_render);
	});

	$$self.$$set = $$props => {
		if ('render' in $$props) $$invalidate(1, render = $$props.render);
		if ('$$scope' in $$props) $$invalidate(2, $$scope = $$props.$$scope);
	};

	return [renderer, render, $$scope, slots];
}

class SceneGL extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { render: 1 });
	}
}

export default SceneGL;