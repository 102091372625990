/* src/App.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	attr,
	check_outros,
	component_subscribe,
	construct_svelte_component,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import './styles/app.css';
import { isMobile, color, loaded } from './lib/stores/index.js';
import { onMount } from '../_snowpack/pkg/svelte.js';
import gsap from '../_snowpack/pkg/gsap.js';

function create_fragment(ctx) {
	let div;
	let switch_instance;
	let current;
	var switch_value = /*Component*/ ctx[0];

	function switch_props(ctx) {
		return {};
	}

	if (switch_value) {
		switch_instance = construct_svelte_component(switch_value, switch_props(ctx));
	}

	return {
		c() {
			div = element("div");
			if (switch_instance) create_component(switch_instance.$$.fragment);
			attr(div, "id", "app");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (switch_instance) mount_component(switch_instance, div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (switch_value !== (switch_value = /*Component*/ ctx[0])) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = construct_svelte_component(switch_value, switch_props(ctx));
					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, div, null);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (switch_instance) destroy_component(switch_instance);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let colorIcon;
	let favicon;
	let $loaded;
	let $color;
	let $isMobile;
	component_subscribe($$self, loaded, $$value => $$invalidate(4, $loaded = $$value));
	component_subscribe($$self, color, $$value => $$invalidate(5, $color = $$value));
	component_subscribe($$self, isMobile, $$value => $$invalidate(6, $isMobile = $$value));
	let Component;
	let linkState = { visible: 1 };
	let visible = 1;
	history.scrollRestoration = 'manual';

	onMount(async () => {
		const colors = ['red', 'green', 'blue'];
		const _color = colors[Math.floor(Math.random() * colors.length)];
		color.set(_color);
		document.body.classList.add(_color);

		if ($isMobile) {
			$$invalidate(0, Component = (await import("./lib/Mobile.svelte.js")).default);
		} else {
			$$invalidate(0, Component = (await import("./lib/Desktop.svelte.js")).default);
		}
	});

	function handleLoaded() {
		if ($loaded) {
			gsap.to(linkState, {
				visible: 0,
				repeatDelay: 0.35,
				duration: 0.5,
				yoyo: true,
				repeat: -1,
				onRepeat() {
					$$invalidate(1, visible = !visible);
				}
			});
		}
	}

	function handleFavicon() {
		const oldLink = document.getElementById('dynamic-icon');
		const link = document.createElement('link');
		link.id = 'dynamic-favicon';
		link.rel = 'shortcut icon';
		link.href = `/${favicon}.ico`;

		if (oldLink) {
			document.head.removeChild(oldLink);
		}

		document.head.appendChild(link);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*visible, $color*/ 34) {
			$: $$invalidate(3, colorIcon = visible ? $color : 'blank');
		}

		if ($$self.$$.dirty & /*$loaded, colorIcon*/ 24) {
			$: $$invalidate(2, favicon = $loaded ? colorIcon : `favicon`);
		}

		if ($$self.$$.dirty & /*favicon*/ 4) {
			$: handleFavicon(favicon);
		}

		if ($$self.$$.dirty & /*$loaded*/ 16) {
			$: handleLoaded($loaded);
		}
	};

	return [Component, visible, favicon, colorIcon, $loaded, $color];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;