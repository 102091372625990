import './Info.svelte.css';
/* src/lib/components/home/Info.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../../../_snowpack/pkg/svelte/internal.js";

import projects from '../../../data/projects.js';
import { slider } from '../../stores/index.js';
import ProjectName from './ProjectName.svelte.js';
import ProjectDescription from './ProjectDescription.svelte.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[1] = list[i].description;
	child_ctx[3] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i].name;
	child_ctx[3] = i;
	return child_ctx;
}

// (10:2) {#each projects as { name }
function create_each_block_1(ctx) {
	let projectname;
	let current;

	projectname = new ProjectName({
			props: {
				title: /*name*/ ctx[4],
				visible: /*i*/ ctx[3] === /*$slider*/ ctx[0]
			}
		});

	return {
		c() {
			create_component(projectname.$$.fragment);
		},
		m(target, anchor) {
			mount_component(projectname, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const projectname_changes = {};
			if (dirty & /*$slider*/ 1) projectname_changes.visible = /*i*/ ctx[3] === /*$slider*/ ctx[0];
			projectname.$set(projectname_changes);
		},
		i(local) {
			if (current) return;
			transition_in(projectname.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(projectname.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(projectname, detaching);
		}
	};
}

// (15:2) {#each projects as { description }
function create_each_block(ctx) {
	let projectdescription;
	let current;

	projectdescription = new ProjectDescription({
			props: {
				description: /*description*/ ctx[1],
				visible: /*i*/ ctx[3] === /*$slider*/ ctx[0]
			}
		});

	return {
		c() {
			create_component(projectdescription.$$.fragment);
		},
		m(target, anchor) {
			mount_component(projectdescription, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const projectdescription_changes = {};
			if (dirty & /*$slider*/ 1) projectdescription_changes.visible = /*i*/ ctx[3] === /*$slider*/ ctx[0];
			projectdescription.$set(projectdescription_changes);
		},
		i(local) {
			if (current) return;
			transition_in(projectdescription.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(projectdescription.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(projectdescription, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let div0;
	let t;
	let div1;
	let current;
	let each_value_1 = projects;
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out = i => transition_out(each_blocks_1[i], 1, 1, () => {
		each_blocks_1[i] = null;
	});

	let each_value = projects;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out_1 = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div2 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", "name svelte-88ny3x");
			attr(div1, "class", "description svelte-88ny3x");
			attr(div2, "class", "info svelte-88ny3x");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(div0, null);
			}

			append(div2, t);
			append(div2, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div1, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*projects, $slider*/ 1) {
				each_value_1 = projects;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
						transition_in(each_blocks_1[i], 1);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						transition_in(each_blocks_1[i], 1);
						each_blocks_1[i].m(div0, null);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks_1.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (dirty & /*projects, $slider*/ 1) {
				each_value = projects;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div1, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out_1(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks_1[i]);
			}

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks_1 = each_blocks_1.filter(Boolean);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				transition_out(each_blocks_1[i]);
			}

			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $slider;
	component_subscribe($$self, slider, $$value => $$invalidate(0, $slider = $$value));
	return [$slider];
}

class Info extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Info;