import './Progress.svelte.css';
/* src/lib/components/home/Progress.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style
} from "../../../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div1;
	let div0;

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			attr(div0, "class", "current svelte-no0380");
			set_style(div0, "transform", "scaleX(" + /*value*/ ctx[0] + ")");
			attr(div1, "class", "progress svelte-no0380");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
		},
		p(ctx, [dirty]) {
			if (dirty & /*value*/ 1) {
				set_style(div0, "transform", "scaleX(" + /*value*/ ctx[0] + ")");
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { value } = $$props;

	$$self.$$set = $$props => {
		if ('value' in $$props) $$invalidate(0, value = $$props.value);
	};

	return [value];
}

class Progress extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { value: 0 });
	}
}

export default Progress;