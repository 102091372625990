const projects = [
	{
		date: '07:01:2023',
		day: 7,
		month: 1,
		year: 2023,
		name: 'SHURE - Sound Barriers',
		description:
			'Promotional digital campaign shot for Paramount + featuring actor Luis Gerardo Mendez.',
		image: '00-RB-Portfolio-Shure',
		video: '00-Shure-Sound_Barriers_directors_cut',
		loop: '00-RB-Portfolio-Shure-3Seg'
	},
	{
		date: '03:02:2021',
		day: 1,
		month: 3,
		year: 2021,
		name: 'Future of Music ft Ed Maverick',
		description:
			'Digital campaign shot across three days featuring Universal Latin Group\'s top 8 artists.',
		image: '02-RB-Portfolio-MB-Ed',
		video: '02-RB-Portfolio-MusicBattles-Ed',
		loop: '02-RB-Portfolio-MB-Ed-3Seg'
	},
	{
		date: '04:02:2021',
		day: 2,
		month: 10,
		year: 2020,
		name: 'The Battlers',
		description:
			'App commercial shot for Music Battles integrated launch campaign.',
		image: '03-RB-Portfolio-MusicBattles-TheBattlers',
		video: '03-RB-Portfolio-MusicBattles-TheBattlers',
		loop: '03-RB-Portfolio-MusicBattles-TheBattlers-3Seg'
	},
	{
		date: '05:02:2021',
		day: 14,
		month: 2,
		year: 2022,
		name: 'Cerveza Modelo - Love on Fire',
		description:
			'Science explains love through this commercial shot for Ab InBev group. Table top and live action directed by Ro Bonilla.',
		image: '04-RB-Portfolio-Modelo-LoveOnFire',
		video: '04-RB-Portfolio-Modelo-LoveOnFire',
		loop: '04-RB-Portfolio-Modelo-LoveOnFire-3Seg'
	},
	{
		date: '06:02:2021',
		day: 2,
		month: 2,
		year: 2021,
		name: 'Coca Cola - Bolt',
		description:
			'Intrinsic product campaign shot with Cine Bolt high speed robots.',
		image: '05-RB-Portfolio-CocaCola',
		video: '05-RB-Portfolio-CocaCola',
		loop: '05-RB-Portfolio-CocaCola-3Seg'
	},
	{
		date: '07:02:2021',
		day: 25,
		month: 2,
		year: 2021,
		name: 'Raymix - Traviesa',
		description:
			'Music video shot for Universal Latin Group\'s artist Raymix.',
		image: '06-RB-Portfolio-MusicBattles-Raymix',
		video: '06-RB-Portfolio-MusicBattles-Raymix',
		loop: '06-RB-Portfolio-MusicBattles-Raymix-3Seg'
	},
	{
		date: '07:02:2021',
		day: 2,
		month: 8,
		year: 2019,
		name: 'Victoria Mestizo',
		description:
			'Ro Bonilla directed high speed and table top unit for this integrated digital campaign.',
		image: '07-RB-Portfolio-Victoria-Mestizo',
		video: '07-RB-Portfolio-Victoria-Mestizo',
		loop: '07-RB-Portfolio-Victoria-Mestizo-3Seg'
	},
	{
		date: '07:02:2021',
		day: 3,
		month: 5,
		year: 2018,
		name: 'Coca Cola - World Cup',
		description:
			'Shot on location on Azteca Stadium for 2018 FIFA World Cup.',
		image: '08-RB-Portfolio-CocaCola-WorldCup',
		video: '08-RB-Portfolio-CocaCola-WorldCup',
		loop: '08-RB-Portfolio-CocaCola-WorldCup-3Seg'
	},
	{
		date: '07:02:2021',
		day: 9,
		month: 2,
		year: 2016,
		name: 'La Gusana Ciega - Tu Volverás',
		description:
			'Music video shot on NYC.',
		image: '09-RB-Portfolio-LaGusanaCiega-Clip',
		video: '09-RB-Portfolio-LaGusanaCiega-Clip',
		loop: '09-RB-Portfolio-LaGusanaCiega-Clip-3Seg'
	}
];

export default projects;
