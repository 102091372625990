/* src/lib/components/Preloader.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	component_subscribe,
	create_slot,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot_base
} from "../../../_snowpack/pkg/svelte/internal.js";

import projects from '../../data/projects.js';
import { createEventDispatcher, onMount } from '../../../_snowpack/pkg/svelte.js';
import preloaderLib from '../../../_snowpack/pkg/preload-it.js';

import {
	preloader,
	extension,
	getImagePath,
	loaded,
	preloaderLoaded
} from '../stores/index.js';

function create_fragment(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[2].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[1], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 2)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[1],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[1])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[1], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $preloaderLoaded;
	let $preloader;
	component_subscribe($$self, preloaderLoaded, $$value => $$invalidate(0, $preloaderLoaded = $$value));
	component_subscribe($$self, preloader, $$value => $$invalidate(3, $preloader = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	const assets = [`/assets/noise.${extension}`, `/assets/disp1.${extension}`];
	const dispatch = createEventDispatcher();

	onMount(() => {
		const loader = preloaderLib();

		loader.oncomplete = () => {
			loaded.set(true);
			dispatch('progress', { progress: 100, text: 'RB' });
		};

		loader.onprogress = event => {
			let p = '' + event.progress;
			if (p.length < 2) p = '0' + p;
			if (p === '100') p = '99';
			dispatch('progress', { progress: event.progress, text: p });
		};

		preloader.set(loader);
	});

	function handlePreloaderLoaded(val) {
		if (val) {
			for (const project of projects) {
				assets.push(`/assets/loops/${project.loop}.mp4`);
				assets.push(getImagePath(project.image));
			}

			$preloader.fetch([...new Set(assets)]);
		}
	}

	$$self.$$set = $$props => {
		if ('$$scope' in $$props) $$invalidate(1, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$preloaderLoaded*/ 1) {
			$: handlePreloaderLoaded($preloaderLoaded);
		}
	};

	return [$preloaderLoaded, $$scope, slots];
}

class Preloader extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Preloader;