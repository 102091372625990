import { getContext, setContext } from '../../../../../_snowpack/pkg/svelte.js';

export const RENDERER = {};
export const GL = {};
export const SCENE = {};
export const PARENT = {};
export const CAMERA = {};

export function get_gl() {
	return getContext(GL);
}

export function get_scene() {
	return getContext(SCENE);
}

export function get_renderer() {
	return getContext(RENDERER);
}

export function get_camera() {
	return getContext(CAMERA);
}

export function get_parent() {
	return getContext(PARENT);
}

export function set_scene(scene) {
	return setContext(SCENE, scene);
}

export function set_gl(gl) {
	return setContext(GL, gl);
}

export function set_renderer(renderer) {
	return setContext(RENDERER, renderer);
}

export function set_camera(camera) {
	return setContext(CAMERA, camera);
}

export function set_parent(parent) {
	return setContext(PARENT, parent);
}
