import './Mobile.svelte.css';
/* src/lib/Mobile.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import data from '../data/projects.js';
import gsap from '../../_snowpack/pkg/gsap.js';
import { ScrollTrigger } from '../../_snowpack/pkg/gsap/ScrollTrigger.js';
import MobileVideo from './mobile/MobileVideo.svelte.js';
import { onMount } from '../../_snowpack/pkg/svelte.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	child_ctx[10] = i;
	return child_ctx;
}

// (64:2) {#each data as project, i}
function create_each_block(ctx) {
	let mobilevideo;
	let current;

	mobilevideo = new MobileVideo({
			props: {
				video: /*project*/ ctx[8].video,
				image: /*project*/ ctx[8].image,
				name: /*project*/ ctx[8].name,
				date: /*project*/ ctx[8].date,
				index: /*i*/ ctx[10]
			}
		});

	return {
		c() {
			create_component(mobilevideo.$$.fragment);
		},
		m(target, anchor) {
			mount_component(mobilevideo, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(mobilevideo.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(mobilevideo.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(mobilevideo, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div4;
	let div0;
	let t1;
	let div1;
	let t2;
	let div2;
	let t14;
	let div3;
	let current;
	let each_value = data;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div4 = element("div");
			div0 = element("div");

			div0.innerHTML = `<svg fill="none" height="11" viewBox="0 0 10 11" width="10" class="left svelte-8ky2wi"><path clip-rule="evenodd" d="m9.5 5.5-10 5v-10z" fill="#ff1212" fill-rule="evenodd" class="svelte-8ky2wi"></path></svg> 

		<svg fill="none" height="11" viewBox="0 0 10 11" width="10" class="right svelte-8ky2wi"><path clip-rule="evenodd" d="m.5 5.5 10 5v-10z" fill="#ff1212" fill-rule="evenodd" class="svelte-8ky2wi"></path></svg>`;

			t1 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t2 = space();
			div2 = element("div");

			div2.innerHTML = `<p class="svelte-8ky2wi">A hands-on filmmaker, Rodrigo&#39;s unwavering passion for film is evident in
			the unique insight he brings to each stage of the filmmaking process -
			writing, directing &amp; editing - his unparalleled ability to clearly see
			through an idea from its inception to screen.<br/><br/>Rodrigo has
			collaborated
			with some of the world&#39;s biggest brands including AB-Inbev, Coca-Cola,
			Mondelez &amp; Universal Music.</p> 

		<p class="svelte-8ky2wi">With a background in Executive Production, Rodrigo perfectly understands
			the set and how to create films with perfect craft in time and
			budget.<br/><br/>Rodrigo&#39;s work in advertising has been nominated by,
			and
			has won,
			a plethora of industry leading awards including at the Effies, D&amp;AD’s, One
			Show, CC &amp; Clio.</p> 

		<a class="email svelte-8ky2wi" href="mailto:hello@robonilla.com">hello@robonilla.com</a> 

		<ul class="svelte-8ky2wi"><li><a href="https://www.instagram.com/robonilla/" target="_blank" class="svelte-8ky2wi">Instagram</a></li> 
			<li><a href="https://vimeo.com/robonilla" target="_blank" class="svelte-8ky2wi">Vimeo</a></li></ul>`;

			t14 = space();
			div3 = element("div");
			attr(div0, "class", "cursor svelte-8ky2wi");
			attr(div1, "class", "mobile-content projects svelte-8ky2wi");
			attr(div2, "class", "mobile-content about svelte-8ky2wi");
			attr(div3, "class", "dot svelte-8ky2wi");
			attr(div4, "class", "mobile svelte-8ky2wi");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, div0);
			/*div0_binding*/ ctx[4](div0);
			append(div4, t1);
			append(div4, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div1, null);
			}

			/*div1_binding*/ ctx[5](div1);
			append(div4, t2);
			append(div4, div2);
			/*div2_binding*/ ctx[6](div2);
			append(div4, t14);
			append(div4, div3);
			/*div3_binding*/ ctx[7](div3);
			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*data*/ 0) {
				each_value = data;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div1, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div4);
			/*div0_binding*/ ctx[4](null);
			destroy_each(each_blocks, detaching);
			/*div1_binding*/ ctx[5](null);
			/*div2_binding*/ ctx[6](null);
			/*div3_binding*/ ctx[7](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	gsap.registerPlugin(ScrollTrigger);
	let projects;
	let about;
	let dot;
	let progress;

	onMount(() => {
		ScrollTrigger.normalizeScroll(true);

		gsap.from(progress, {
			top: '-1%',
			scrollTrigger: {
				trigger: projects,
				start: 'top top',
				end: 'bottom+=35% bottom',
				scrub: true
			}
		});

		ScrollTrigger.create({
			trigger: about,
			pin: projects,
			pinSpacing: false,
			start: 'top bottom'
		});

		gsap.to(dot, {
			backgroundColor: 'white',
			scrollTrigger: {
				scrub: true,
				trigger: about,
				start: 'top top+=48',
				end: 'top top+=45'
			}
		});
	});

	function div0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			progress = $$value;
			$$invalidate(3, progress);
		});
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			projects = $$value;
			$$invalidate(0, projects);
		});
	}

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			about = $$value;
			$$invalidate(1, about);
		});
	}

	function div3_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			dot = $$value;
			$$invalidate(2, dot);
		});
	}

	return [
		projects,
		about,
		dot,
		progress,
		div0_binding,
		div1_binding,
		div2_binding,
		div3_binding
	];
}

class Mobile extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Mobile;