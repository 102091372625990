import './WebGL.svelte.css';
/* src/lib/components/home/WebGL.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	attr,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	globals,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	set_store_value,
	space,
	transition_in,
	transition_out
} from "../../../../_snowpack/pkg/svelte/internal.js";

const { window: window_1 } = globals;
import { writable } from '../../../../_snowpack/pkg/svelte/store.js';
import { set_gl, set_renderer } from './webgl/context.js';
import { onMount } from '../../../../_snowpack/pkg/svelte.js';
import { Renderer } from '../../../../_snowpack/pkg/ogl.js';
import Emitter from '../../utils/Emitter.js';
import CameraGL from './webgl/CameraGL.svelte.js';
import SceneGL from './webgl/SceneGL.svelte.js';
import PostProcessing from './webgl/PostProcessing.svelte.js';
import Video from './webgl/Video.svelte.js';
import gsap from "../../../../_snowpack/pkg/gsap.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[10] = list[i];
	child_ctx[12] = i;
	return child_ctx;
}

// (67:0) {#if initialized}
function create_if_block(ctx) {
	let cameragl;
	let current;

	cameragl = new CameraGL({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(cameragl.$$.fragment);
		},
		m(target, anchor) {
			mount_component(cameragl, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const cameragl_changes = {};

			if (dirty & /*$$scope, elements*/ 8196) {
				cameragl_changes.$$scope = { dirty, ctx };
			}

			cameragl.$set(cameragl_changes);
		},
		i(local) {
			if (current) return;
			transition_in(cameragl.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(cameragl.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(cameragl, detaching);
		}
	};
}

// (71:4) {#each elements as element, i}
function create_each_block(ctx) {
	let video;
	let current;

	video = new Video({
			props: {
				element: /*element*/ ctx[10],
				index: /*i*/ ctx[12]
			}
		});

	return {
		c() {
			create_component(video.$$.fragment);
		},
		m(target, anchor) {
			mount_component(video, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const video_changes = {};
			if (dirty & /*elements*/ 4) video_changes.element = /*element*/ ctx[10];
			video.$set(video_changes);
		},
		i(local) {
			if (current) return;
			transition_in(video.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(video.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(video, detaching);
		}
	};
}

// (70:3) <PostProcessing>
function create_default_slot_2(ctx) {
	let each_1_anchor;
	let current;
	let each_value = /*elements*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*elements*/ 4) {
				each_value = /*elements*/ ctx[2];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (69:2) <SceneGL render={false}>
function create_default_slot_1(ctx) {
	let postprocessing;
	let current;

	postprocessing = new PostProcessing({
			props: {
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(postprocessing.$$.fragment);
		},
		m(target, anchor) {
			mount_component(postprocessing, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const postprocessing_changes = {};

			if (dirty & /*$$scope, elements*/ 8196) {
				postprocessing_changes.$$scope = { dirty, ctx };
			}

			postprocessing.$set(postprocessing_changes);
		},
		i(local) {
			if (current) return;
			transition_in(postprocessing.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(postprocessing.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(postprocessing, detaching);
		}
	};
}

// (68:1) <CameraGL>
function create_default_slot(ctx) {
	let scenegl;
	let current;

	scenegl = new SceneGL({
			props: {
				render: false,
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(scenegl.$$.fragment);
		},
		m(target, anchor) {
			mount_component(scenegl, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const scenegl_changes = {};

			if (dirty & /*$$scope, elements*/ 8196) {
				scenegl_changes.$$scope = { dirty, ctx };
			}

			scenegl.$set(scenegl_changes);
		},
		i(local) {
			if (current) return;
			transition_in(scenegl.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(scenegl.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(scenegl, detaching);
		}
	};
}

function create_fragment(ctx) {
	let canvas_1;
	let t;
	let if_block_anchor;
	let current;
	let mounted;
	let dispose;
	let if_block = /*initialized*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			canvas_1 = element("canvas");
			t = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(canvas_1, "class", "canvas svelte-vfcgg4");
		},
		m(target, anchor) {
			insert(target, canvas_1, anchor);
			/*canvas_1_binding*/ ctx[6](canvas_1);
			insert(target, t, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(window_1, "resize", /*resize*/ ctx[5]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*initialized*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*initialized*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(canvas_1);
			/*canvas_1_binding*/ ctx[6](null);
			if (detaching) detach(t);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $gl;
	let $renderer;
	let initialized = false;
	let canvas;
	let elements;
	let _renderer;
	const renderer = writable(null);
	component_subscribe($$self, renderer, value => $$invalidate(9, $renderer = value));
	const gl = writable(null);
	component_subscribe($$self, gl, value => $$invalidate(8, $gl = value));
	set_renderer(renderer);
	set_gl(gl);

	onMount(() => {
		$$invalidate(2, elements = document.querySelectorAll('[data-project]'));

		_renderer = new Renderer({
				canvas,
				width: window.innerWidth,
				height: window.innerHeight,
				dpr: Math.min(2, window.devicePixelRatio),
				antialias: false,
				alpha: true,
				premultipliedAlpha: true,
				powerPreference: 'high-performance'
			});

		set_store_value(renderer, $renderer = _renderer, $renderer);
		set_store_value(gl, $gl = _renderer.gl, $gl);
		resize();
		gsap.ticker.add(loop);

		//let frame = requestAnimationFrame(loop);
		function loop(t, dt) {
			//frame = requestAnimationFrame(loop);
			Emitter.emit('render', t * 1000, dt);
		}

		$$invalidate(0, initialized = true);

		return () => {
			gsap.ticker.remove(loop);
		}; //cancelAnimationFrame(frame);
	});

	function resize() {
		_renderer.setSize(window.innerWidth, window.innerHeight);
	}

	function canvas_1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			canvas = $$value;
			$$invalidate(1, canvas);
		});
	}

	return [initialized, canvas, elements, renderer, gl, resize, canvas_1_binding];
}

class WebGL extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default WebGL;