import { writable, readable, get } from '../../../_snowpack/pkg/svelte/store.js';
import DetectMobile from '../utils/DetectMobile.js';
import DetectWebp from '../utils/DetectWebp.js';
import preloaderLib from '../../../_snowpack/pkg/preload-it.js';
import { Howl } from '../../../_snowpack/pkg/howler.js';
import gsap from '../../../_snowpack/pkg/gsap.js';

function createLoader() {
	const loaders = {};
	return {
		has: (key) => {
			return loaders[key];
		},
		preload: (index, key) => {
			loaders[index] = preloaderLib();
			loaders[index].fetch([key]);
			return loaders[index];
		}
	};
}

export const pr = gsap.utils.clamp(1, 2, Math.round(window.devicePixelRatio));
export const imageDir = '/assets/images/';
export const loopDir = '/assets/loops/';
export const videoDir = '/assets/videos/';
export const hasWebp = DetectWebp();
export const extension = hasWebp ? 'webp' : 'jpg';
export const dpr = `${pr}x`;

export const slider = writable(0);
export const speed = writable(0);
export const isMobile = readable(DetectMobile());
export const preloader = writable(null);
export const scrollLocked = writable(true);
export const mouseSpeed = writable(0);
export const selectedProject = writable(null);
export const loaders = createLoader();
export const projectLoading = writable(null);
export const scrollGlobalTween = writable(null);
export const mouseDown = writable(false);
export const scrollSmoother = writable(null);
export const videoPaused = writable(false);
export const color = writable("grey");
export const loaded = writable(false);
export const blinking = writable(false);
export const entered = writable(false);
export const preloaderLoaded = writable(false);

export const tvOn = readable(new Howl({
	src: ['/assets/sfx/tv_on.mp3'],
	preload: true,
	volume: 0.5,
}));

export const tvOff = readable(new Howl({
	src: ['/assets/sfx/tv_off.mp3'],
	preload: true,
	volume: 0.5,
}));

export const click = readable(new Howl({
	src: ['/assets/sfx/click.mp3'],
	preload: true,
	volume: 0.5,
}));

export const button = readable(new Howl({
	src: ['/assets/sfx/button.mp3'],
	preload: true,
}));

export const whiteNoise = readable(new Howl({
	src: ['/assets/sfx/static.mp3'],
	preload: true,
	loop: true,
	volume: 0.6,
}));

export function getImageUrl(image) {
	if (!image) return '';
	return get(preloader).getItemByUrl(getImagePath(image)).blobUrl;
}

export function getImagePath(image) {
	return `${imageDir}${dpr}/${image}.${extension}`;
}
