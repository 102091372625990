import './ProjectPlayer.svelte.css';
/* src/lib/components/home/webgl/ProjectPlayer.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	bubble,
	check_outros,
	component_subscribe,
	create_in_transition,
	create_out_transition,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	transition_in,
	transition_out
} from "../../../../../_snowpack/pkg/svelte/internal.js";

import { button, selectedProject, tvOff, videoPaused } from '../../../stores/index.js';
import { onMount } from '../../../../../_snowpack/pkg/svelte.js';
import { fade } from '../../../../../_snowpack/pkg/svelte/transition.js';
import gsap from '../../../../../_snowpack/pkg/gsap.js';

function create_if_block(ctx) {
	let div6;
	let div0;
	let t0;
	let div5;
	let div3;
	let div1;
	let t1;
	let div2;
	let div3_intro;
	let div3_outro;
	let t3;
	let t4;
	let div4;
	let h20;
	let t5;
	let t6;
	let h21;
	let t7;
	let div4_intro;
	let div4_outro;
	let current;
	let mounted;
	let dispose;

	function select_block_type(ctx, dirty) {
		if (/*state*/ ctx[5] === "paused") return create_if_block_2;
		if (/*state*/ ctx[5] === "forward") return create_if_block_3;
		if (/*state*/ ctx[5] === "rewind") return create_if_block_4;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block0 = current_block_type(ctx);
	let if_block1 = /*localPaused*/ ctx[3] && create_if_block_1(ctx);

	return {
		c() {
			div6 = element("div");
			div0 = element("div");
			t0 = space();
			div5 = element("div");
			div3 = element("div");
			div1 = element("div");
			if_block0.c();
			t1 = space();
			div2 = element("div");
			div2.textContent = "Close";
			t3 = space();
			if (if_block1) if_block1.c();
			t4 = space();
			div4 = element("div");
			h20 = element("h2");
			t5 = text(/*title*/ ctx[1]);
			t6 = space();
			h21 = element("h2");
			t7 = text(/*mediaTime*/ ctx[4]);
			attr(div0, "class", "project-absorber svelte-tfaqqr");
			set_style(div0, "cursor", /*mouseDown*/ ctx[2] ? 'grabbing' : 'grab');
			attr(div1, "class", "player-button svelte-tfaqqr");
			attr(div2, "class", "player-button svelte-tfaqqr");
			attr(div3, "class", "row svelte-tfaqqr");
			attr(h20, "class", "title svelte-tfaqqr");
			attr(h21, "class", "svelte-tfaqqr");
			attr(div4, "class", "row svelte-tfaqqr");
			attr(div5, "class", "content svelte-tfaqqr");
			attr(div6, "class", "project-player svelte-tfaqqr");
		},
		m(target, anchor) {
			insert(target, div6, anchor);
			append(div6, div0);
			append(div6, t0);
			append(div6, div5);
			append(div5, div3);
			append(div3, div1);
			if_block0.m(div1, null);
			append(div3, t1);
			append(div3, div2);
			append(div5, t3);
			if (if_block1) if_block1.m(div5, null);
			append(div5, t4);
			append(div5, div4);
			append(div4, h20);
			append(h20, t5);
			append(div4, t6);
			append(div4, h21);
			append(h21, t7);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div0, "mousedown", /*mousedown_handler*/ ctx[15]),
					listen(div0, "mouseup", /*mouseup_handler*/ ctx[16]),
					listen(div1, "click", /*onPlayHandler*/ ctx[6]),
					listen(div2, "click", /*onCloseHandler*/ ctx[7]),
					listen(div6, "mousemove", /*mousemove_handler*/ ctx[14])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (!current || dirty & /*mouseDown*/ 4) {
				set_style(div0, "cursor", /*mouseDown*/ ctx[2] ? 'grabbing' : 'grab');
			}

			if (current_block_type !== (current_block_type = select_block_type(ctx, dirty))) {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(div1, null);
				}
			}

			if (/*localPaused*/ ctx[3]) {
				if (if_block1) {
					
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					if_block1.m(div5, t4);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (!current || dirty & /*title*/ 2) set_data(t5, /*title*/ ctx[1]);
			if (!current || dirty & /*mediaTime*/ 16) set_data(t7, /*mediaTime*/ ctx[4]);
		},
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (div3_outro) div3_outro.end(1);
				div3_intro = create_in_transition(div3, fade, { delay: 300, duration: 100 });
				div3_intro.start();
			});

			add_render_callback(() => {
				if (div4_outro) div4_outro.end(1);
				div4_intro = create_in_transition(div4, fade, { delay: 300, duration: 100 });
				div4_intro.start();
			});

			current = true;
		},
		o(local) {
			if (div3_intro) div3_intro.invalidate();
			div3_outro = create_out_transition(div3, fade, { delay: 0, duration: 100 });
			if (div4_intro) div4_intro.invalidate();
			div4_outro = create_out_transition(div4, fade, { delay: 0, duration: 100 });
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div6);
			if_block0.d();
			if (detaching && div3_outro) div3_outro.end();
			if (if_block1) if_block1.d();
			if (detaching && div4_outro) div4_outro.end();
			mounted = false;
			run_all(dispose);
		}
	};
}

// (128:5) {:else}
function create_else_block(ctx) {
	let span;
	let t1;
	let i;

	return {
		c() {
			span = element("span");
			span.textContent = "Play";
			t1 = space();
			i = element("i");
			i.textContent = "play_arrow";
			attr(i, "class", "material-icons");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			insert(target, t1, anchor);
			insert(target, i, anchor);
		},
		d(detaching) {
			if (detaching) detach(span);
			if (detaching) detach(t1);
			if (detaching) detach(i);
		}
	};
}

// (126:34) 
function create_if_block_4(ctx) {
	let span;
	let t1;
	let i;

	return {
		c() {
			span = element("span");
			span.textContent = "REW";
			t1 = space();
			i = element("i");
			i.textContent = "fast_rewind";
			attr(i, "class", "material-icons");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			insert(target, t1, anchor);
			insert(target, i, anchor);
		},
		d(detaching) {
			if (detaching) detach(span);
			if (detaching) detach(t1);
			if (detaching) detach(i);
		}
	};
}

// (124:35) 
function create_if_block_3(ctx) {
	let span;
	let t1;
	let i;

	return {
		c() {
			span = element("span");
			span.textContent = "FFWD";
			t1 = space();
			i = element("i");
			i.textContent = "fast_forward";
			attr(i, "class", "material-icons");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			insert(target, t1, anchor);
			insert(target, i, anchor);
		},
		d(detaching) {
			if (detaching) detach(span);
			if (detaching) detach(t1);
			if (detaching) detach(i);
		}
	};
}

// (122:5) {#if state === "paused"}
function create_if_block_2(ctx) {
	let span;
	let t1;
	let i;

	return {
		c() {
			span = element("span");
			span.textContent = "Pause";
			t1 = space();
			i = element("i");
			i.textContent = "pause";
			attr(i, "class", "material-icons");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			insert(target, t1, anchor);
			insert(target, i, anchor);
		},
		d(detaching) {
			if (detaching) detach(span);
			if (detaching) detach(t1);
			if (detaching) detach(i);
		}
	};
}

// (139:3) {#if localPaused}
function create_if_block_1(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			div.textContent = "PAUSED";
			attr(div, "class", "paused svelte-tfaqqr");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*visible*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*visible*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*visible*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let seekDirection;
	let pauseState;
	let state;
	let $tvOff;
	let $button;
	component_subscribe($$self, tvOff, $$value => $$invalidate(17, $tvOff = $$value));
	component_subscribe($$self, button, $$value => $$invalidate(18, $button = $$value));
	let { visible } = $$props;
	let { title } = $$props;
	let { video } = $$props;
	let { mouseDown = false } = $$props;
	let { mouseDirection = null } = $$props;
	let paused = false;
	let localPaused = false;
	let mediaTime = '00:00';
	let seeking = false;

	onMount(() => {
		video.addEventListener('ended', onVideoEnd);
		video.addEventListener('play', onVideoPlay);
		video.addEventListener('pause', onVideoPause);
		video.addEventListener('seeking', onVideoSeeking);
		video.addEventListener('seeked', onVideoSeeked);
		video.addEventListener('timeupdate', onVideoTimeUpdate);
	});

	function onKeyHandler(e) {
		if (e.key === 'Escape') {
			onCloseHandler();
		}

		if (e.key === ' ') {
			onPlayHandler();
		}
	}

	function onVideoSeeking() {
		$$invalidate(11, seeking = true);
	}

	function onVideoSeeked() {
		gsap.delayedCall(1, () => {
			$$invalidate(11, seeking = false);
		});
	}

	function onVideoEnd() {
		$button.play();
		video.pause();
		$$invalidate(3, localPaused = true);
		$$invalidate(8, video.currentTime = 0, video);
	}

	function onVideoPlay() {
		$$invalidate(10, paused = false);
		$$invalidate(3, localPaused = false);
	}

	function onVideoPause() {
		$$invalidate(10, paused = true);
	}

	function onVideoTimeUpdate() {
		let minutes = Math.floor(video.currentTime / 60);
		let seconds = Math.floor(video.currentTime - minutes * 60);
		let minuteValue;
		let secondValue;

		if (minutes < 10) {
			minuteValue = '0' + minutes;
		} else {
			minuteValue = minutes;
		}

		if (seconds < 10) {
			secondValue = '0' + seconds;
		} else {
			secondValue = seconds;
		}

		$$invalidate(4, mediaTime = minuteValue + ':' + secondValue);
	}

	function onPlayHandler() {
		$button.play();
		video.paused ? video.play() : video.pause();
		$$invalidate(3, localPaused = video.paused);
	}

	function onCloseHandler() {
		$$invalidate(3, localPaused = false);
		$tvOff.play();
		selectedProject.set(null);
	}

	function handleLocalPaused(isPaused) {
		videoPaused.set(isPaused);
	}

	function handleVisible(isVisible) {
		if (isVisible) {
			window.addEventListener('keyup', onKeyHandler);
		} else {
			window.removeEventListener('keyup', onKeyHandler);
		}
	}

	function mousemove_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mousedown_handler(event) {
		bubble.call(this, $$self, event);
	}

	function mouseup_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$props => {
		if ('visible' in $$props) $$invalidate(0, visible = $$props.visible);
		if ('title' in $$props) $$invalidate(1, title = $$props.title);
		if ('video' in $$props) $$invalidate(8, video = $$props.video);
		if ('mouseDown' in $$props) $$invalidate(2, mouseDown = $$props.mouseDown);
		if ('mouseDirection' in $$props) $$invalidate(9, mouseDirection = $$props.mouseDirection);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*visible*/ 1) {
			$: handleVisible(visible);
		}

		if ($$self.$$.dirty & /*localPaused*/ 8) {
			$: handleLocalPaused(localPaused);
		}

		if ($$self.$$.dirty & /*mouseDirection*/ 512) {
			$: $$invalidate(13, seekDirection = mouseDirection === 'forward' ? 'forward' : 'rewind');
		}

		if ($$self.$$.dirty & /*mouseDown, seeking, seekDirection*/ 10244) {
			$: $$invalidate(12, pauseState = mouseDown && seeking ? seekDirection : 'paused');
		}

		if ($$self.$$.dirty & /*paused, pauseState*/ 5120) {
			$: $$invalidate(5, state = paused ? pauseState : 'play');
		}
	};

	return [
		visible,
		title,
		mouseDown,
		localPaused,
		mediaTime,
		state,
		onPlayHandler,
		onCloseHandler,
		video,
		mouseDirection,
		paused,
		seeking,
		pauseState,
		seekDirection,
		mousemove_handler,
		mousedown_handler,
		mouseup_handler
	];
}

class ProjectPlayer extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			visible: 0,
			title: 1,
			video: 8,
			mouseDown: 2,
			mouseDirection: 9
		});
	}
}

export default ProjectPlayer;