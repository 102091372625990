import './Desktop.svelte.css';
/* src/lib/Desktop.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	globals,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

const { document: document_1 } = globals;
import Preloader from './components/Preloader.svelte.js';
import { mouseSpeed, scrollLocked } from './stores/index.js';
import { onMount } from '../../_snowpack/pkg/svelte.js';
import Background from './components/Background.svelte.js';
import Scroller from './components/home/Scroller.svelte.js';

function create_if_block_1(ctx) {
	let scroller;
	let current;
	scroller = new Scroller({});

	return {
		c() {
			create_component(scroller.$$.fragment);
		},
		m(target, anchor) {
			mount_component(scroller, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(scroller.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(scroller.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(scroller, detaching);
		}
	};
}

// (65:0) <Preloader on:progress={onLoaderProgress}>
function create_default_slot(ctx) {
	let div13;
	let div12;
	let t10;
	let background;
	let t11;
	let current;

	background = new Background({
			props: { progress: /*loaderProgress*/ ctx[1] }
		});

	background.$on("complete", /*onLoaderComplete*/ ctx[2]);
	let if_block = /*loaded*/ ctx[0] && create_if_block_1(ctx);

	return {
		c() {
			div13 = element("div");
			div12 = element("div");

			div12.innerHTML = `<div class="fake-videos svelte-i9z03l" id="videos"><div class="fake-video svelte-i9z03l" id="video-1"></div> 
				<div class="fake-video svelte-i9z03l" id="video-2"></div> 
				<div class="fake-video svelte-i9z03l" id="video-3"></div> 
				<div class="fake-video svelte-i9z03l" id="video-4"></div> 
				<div class="fake-video svelte-i9z03l" id="video-5"></div> 
				<div class="fake-video svelte-i9z03l" id="video-6"></div> 
				<div class="fake-video svelte-i9z03l" id="video-7"></div> 
				<div class="fake-video svelte-i9z03l" id="video-8"></div> 
				<div class="fake-video svelte-i9z03l" id="video-9"></div></div> 
			<div class="fake-about svelte-i9z03l" id="about"></div> 
			<div class="fake-about-scroll svelte-i9z03l" id="about-scroll"></div>`;

			t10 = space();
			create_component(background.$$.fragment);
			t11 = space();
			if (if_block) if_block.c();
			attr(div12, "id", "content");
			attr(div12, "class", "svelte-i9z03l");
			attr(div13, "id", "viewport");
			attr(div13, "class", "svelte-i9z03l");
		},
		m(target, anchor) {
			insert(target, div13, anchor);
			append(div13, div12);
			append(div13, t10);
			mount_component(background, div13, null);
			append(div13, t11);
			if (if_block) if_block.m(div13, null);
			current = true;
		},
		p(ctx, dirty) {
			const background_changes = {};
			if (dirty & /*loaderProgress*/ 2) background_changes.progress = /*loaderProgress*/ ctx[1];
			background.$set(background_changes);

			if (/*loaded*/ ctx[0]) {
				if (if_block) {
					if (dirty & /*loaded*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div13, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(background.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(background.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div13);
			destroy_component(background);
			if (if_block) if_block.d();
		}
	};
}

// (88:0) {#if !loaded}
function create_if_block(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			attr(div, "class", "blocker svelte-i9z03l");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let link;
	let t0;
	let preloader;
	let t1;
	let if_block_anchor;
	let current;
	let mounted;
	let dispose;

	preloader = new Preloader({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	preloader.$on("progress", /*onLoaderProgress*/ ctx[3]);
	let if_block = !/*loaded*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			link = element("link");
			t0 = space();
			create_component(preloader.$$.fragment);
			t1 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(link, "href", "https://fonts.googleapis.com/icon?family=Material+Icons");
			attr(link, "rel", "stylesheet");
		},
		m(target, anchor) {
			append(document_1.head, link);
			insert(target, t0, anchor);
			mount_component(preloader, target, anchor);
			insert(target, t1, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = [
					listen(window, "mousedown", /*onMouseMove*/ ctx[4]),
					listen(window, "mousemove", /*onMouseMove*/ ctx[4])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			const preloader_changes = {};

			if (dirty & /*$$scope, loaded, loaderProgress*/ 1027) {
				preloader_changes.$$scope = { dirty, ctx };
			}

			preloader.$set(preloader_changes);

			if (!/*loaded*/ ctx[0]) {
				if (if_block) {
					
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(preloader.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(preloader.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			detach(link);
			if (detaching) detach(t0);
			destroy_component(preloader, detaching);
			if (detaching) detach(t1);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $scrollLocked;
	component_subscribe($$self, scrollLocked, $$value => $$invalidate(5, $scrollLocked = $$value));
	let body = document.body;
	let loaded = false;
	let loaderProgress = 0;
	let previousMousePosition = { x: 0, y: 0 };
	let mousePosition = { x: 0, y: 0 };

	onMount(() => {
		body.style.height = document.querySelector('#app').style.height = '100%';
		let previousT = 0;
		let frame = requestAnimationFrame(loop);

		function loop(t) {
			frame = requestAnimationFrame(loop);
			const x_dist = mousePosition.x - previousMousePosition.x;
			const interval = t - previousT;
			const velocity = x_dist / interval;
			mouseSpeed.set(Math.abs(velocity));
			previousT = t;
			previousMousePosition = mousePosition;
		}

		return () => {
			cancelAnimationFrame(frame);
		};
	});

	function onLoaderComplete() {
		$$invalidate(0, loaded = true);
		scrollLocked.set(false);
	}

	function onLoaderProgress(event) {
		$$invalidate(1, loaderProgress = event.detail.progress);
	}

	function onMouseMove(e) {
		mousePosition = { x: e.clientX, y: e.clientY };
	}

	function handleScrollLock(value) {
		if (body !== undefined) {
			value
			? body.classList.add('locked')
			: body.classList.remove('locked');
		}
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$scrollLocked*/ 32) {
			$: handleScrollLock($scrollLocked);
		}
	};

	return [
		loaded,
		loaderProgress,
		onLoaderComplete,
		onLoaderProgress,
		onMouseMove,
		$scrollLocked
	];
}

class Desktop extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Desktop;