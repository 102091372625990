import './Project.svelte.css';
/* src/lib/components/home/Project.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	component_subscribe,
	detach,
	element,
	flush,
	globals,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	src_url_equal,
	toggle_class
} from "../../../../_snowpack/pkg/svelte/internal.js";

const { window: window_1 } = globals;
import gsap from '../../../../_snowpack/pkg/gsap.js';
import { onMount } from '../../../../_snowpack/pkg/svelte.js';
import CustomEase from '../../../../_snowpack/pkg/gsap/CustomEase.js';

import {
	scrollLocked,
	selectedProject,
	getImageUrl,
	tvOn,
	blinking
} from '../../stores/index.js';

function create_fragment(ctx) {
	let div1;
	let div0;
	let img_1;
	let img_1_src_value;
	let mounted;
	let dispose;

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			img_1 = element("img");
			attr(img_1, "class", "image svelte-gy4fri");
			if (!src_url_equal(img_1.src, img_1_src_value = `${/*imageSource*/ ctx[8]}`)) attr(img_1, "src", img_1_src_value);
			attr(img_1, "alt", /*name*/ ctx[2]);
			attr(img_1, "draggable", "false");
			attr(div0, "class", "container svelte-gy4fri");
			attr(div1, "class", "project svelte-gy4fri");
			attr(div1, "data-project", /*index*/ ctx[1]);
			toggle_class(div1, "disabled", /*$scrollLocked*/ ctx[5]);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, img_1);
			/*img_1_binding*/ ctx[16](img_1);
			/*div0_binding*/ ctx[17](div0);
			/*div1_binding*/ ctx[18](div1);

			if (!mounted) {
				dispose = [
					listen(window_1, "resize", /*onResize*/ ctx[7]),
					listen(div0, "click", /*onClick*/ ctx[6])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*name*/ 4) {
				attr(img_1, "alt", /*name*/ ctx[2]);
			}

			if (dirty & /*index*/ 2) {
				attr(div1, "data-project", /*index*/ ctx[1]);
			}

			if (dirty & /*$scrollLocked*/ 32) {
				toggle_class(div1, "disabled", /*$scrollLocked*/ ctx[5]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			/*img_1_binding*/ ctx[16](null);
			/*div0_binding*/ ctx[17](null);
			/*div1_binding*/ ctx[18](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $tvOn;
	let $selectedProject;
	let $scrollLocked;
	component_subscribe($$self, tvOn, $$value => $$invalidate(20, $tvOn = $$value));
	component_subscribe($$self, selectedProject, $$value => $$invalidate(21, $selectedProject = $$value));
	component_subscribe($$self, scrollLocked, $$value => $$invalidate(5, $scrollLocked = $$value));
	let { index } = $$props;
	let { image } = $$props;
	let { video } = $$props;
	let { name } = $$props;
	let { intersecting = false } = $$props;
	let { el = undefined } = $$props;
	let img;
	let container;
	let selected = false;

	onMount(() => {
		$$invalidate(3, img.style.visibility = 'hidden', img);
	});

	function select() {
		selected = true;
		let rect = container.getBoundingClientRect();

		gsap.to(img, {
			width: '100vw',
			height: '100vh',
			x: -rect.left,
			y: -rect.top,
			ease: CustomEase.create('custom', 'M0,0 C0.432,0 0.17,1 1,1 '),
			onComplete() {
				blinking.set(true);
			}
		});
	}

	function deselect() {
		selected = false;
		blinking.set(false);

		return gsap.to(img, {
			width: '100%',
			height: '25.5vw',
			top: 0,
			left: 0,
			x: 0,
			y: 0,
			ease: CustomEase.create('custom', 'M0,0 C0.432,0 0.17,1 1,1 ')
		});
	}

	function hide(side) {
		let rect = img.getBoundingClientRect();

		gsap.to(img, {
			x: side === 1
			? window.innerWidth - rect.left + window.innerWidth * 0.05
			: -rect.right - window.innerWidth * 0.05,
			ease: CustomEase.create('custom', 'M0,0 C0.432,0 0.17,1 1,1 ')
		});
	}

	function show() {
		gsap.to(img, {
			x: 0,
			ease: CustomEase.create('custom', 'M0,0 C0.432,0 0.17,1 1,1 ')
		});
	}

	async function onClick() {
		if ($selectedProject === index) return;
		selectProject();
		$tvOn.play();
	}

	function selectProject() {
		selectedProject.set(index);
	}

	const onResize = () => {
		if (selected) {
			let rect = container.getBoundingClientRect();

			gsap.set(img, {
				x: -rect.left,
				y: -rect.top,
				ease: CustomEase.create('custom', 'M0,0 C0.432,0 0.17,1 1,1 ')
			});
		}
	};

	let imageSource = getImageUrl(image);

	function img_1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			img = $$value;
			$$invalidate(3, img);
		});
	}

	function div0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			container = $$value;
			$$invalidate(4, container);
		});
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(0, el);
		});
	}

	$$self.$$set = $$props => {
		if ('index' in $$props) $$invalidate(1, index = $$props.index);
		if ('image' in $$props) $$invalidate(9, image = $$props.image);
		if ('video' in $$props) $$invalidate(10, video = $$props.video);
		if ('name' in $$props) $$invalidate(2, name = $$props.name);
		if ('intersecting' in $$props) $$invalidate(11, intersecting = $$props.intersecting);
		if ('el' in $$props) $$invalidate(0, el = $$props.el);
	};

	return [
		el,
		index,
		name,
		img,
		container,
		$scrollLocked,
		onClick,
		onResize,
		imageSource,
		image,
		video,
		intersecting,
		select,
		deselect,
		hide,
		show,
		img_1_binding,
		div0_binding,
		div1_binding
	];
}

class Project extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			index: 1,
			image: 9,
			video: 10,
			name: 2,
			intersecting: 11,
			el: 0,
			select: 12,
			deselect: 13,
			hide: 14,
			show: 15
		});
	}

	get index() {
		return this.$$.ctx[1];
	}

	set index(index) {
		this.$$set({ index });
		flush();
	}

	get image() {
		return this.$$.ctx[9];
	}

	set image(image) {
		this.$$set({ image });
		flush();
	}

	get video() {
		return this.$$.ctx[10];
	}

	set video(video) {
		this.$$set({ video });
		flush();
	}

	get name() {
		return this.$$.ctx[2];
	}

	set name(name) {
		this.$$set({ name });
		flush();
	}

	get intersecting() {
		return this.$$.ctx[11];
	}

	set intersecting(intersecting) {
		this.$$set({ intersecting });
		flush();
	}

	get el() {
		return this.$$.ctx[0];
	}

	set el(el) {
		this.$$set({ el });
		flush();
	}

	get select() {
		return this.$$.ctx[12];
	}

	get deselect() {
		return this.$$.ctx[13];
	}

	get hide() {
		return this.$$.ctx[14];
	}

	get show() {
		return this.$$.ctx[15];
	}
}

export default Project;