import './Projects.svelte.css';
/* src/lib/components/home/Projects.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	attr,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "../../../../_snowpack/pkg/svelte/internal.js";

import data from '../../../data/projects.js';
import { scrollLocked, scrollSmoother, selectedProject } from '../../stores/index.js';
import Project from './Project.svelte.js';
import ScrollTrigger from '../../../../_snowpack/pkg/gsap/ScrollTrigger.js';
import CustomEase from '../../../../_snowpack/pkg/gsap/CustomEase.js';
import gsap from '../../../../_snowpack/pkg/gsap.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[11] = list[i];
	child_ctx[12] = list;
	child_ctx[13] = i;
	return child_ctx;
}

// (79:1) {#each data as project, i}
function create_each_block(ctx) {
	let project;
	let i = /*i*/ ctx[13];
	let current;
	const assign_project = () => /*project_binding*/ ctx[2](project, i);
	const unassign_project = () => /*project_binding*/ ctx[2](null, i);

	let project_props = {
		index: /*i*/ ctx[13],
		image: /*project*/ ctx[11].image,
		video: /*project*/ ctx[11].video,
		name: /*project*/ ctx[11].name
	};

	project = new Project({ props: project_props });
	assign_project();

	return {
		c() {
			create_component(project.$$.fragment);
		},
		m(target, anchor) {
			mount_component(project, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (i !== /*i*/ ctx[13]) {
				unassign_project();
				i = /*i*/ ctx[13];
				assign_project();
			}

			const project_changes = {};
			project.$set(project_changes);
		},
		i(local) {
			if (current) return;
			transition_in(project.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(project.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			unassign_project();
			destroy_component(project, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let current;
	let each_value = data;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "projects svelte-o5nb4c");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*data, projects*/ 1) {
				each_value = data;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $selectedProject;
	let $scrollSmoother;
	component_subscribe($$self, selectedProject, $$value => $$invalidate(1, $selectedProject = $$value));
	component_subscribe($$self, scrollSmoother, $$value => $$invalidate(5, $scrollSmoother = $$value));
	let projects = [];
	let prevSelected = null;
	let lockedIndex = null;

	function handleSelectedProject(index) {
		if (index === null) prevSelected !== null && deselectVideo(); else selectVideo(index);
	}

	function selectVideo(index) {
		lockedIndex = index;
		const total = window.innerHeight * 9;
		const toScroll = $scrollSmoother.offset(`#video-${index + 1}`);
		const currentScrollPosition = window.scrollY;
		const diff = Math.abs(currentScrollPosition - toScroll);
		const delay = diff / total * 6.5;
		$scrollSmoother.paused(true);
		scrollLocked.set(true);

		gsap.to($scrollSmoother, {
			scrollTop: toScroll,
			ease: CustomEase.create('custom', 'M0,0 C0.432,0 0.17,1 1,1 '),
			duration: delay,
			onComplete() {
				hidePrevProjects(index);
				hideNextProjects(index);
				projects[index].select();
				prevSelected = index;
			}
		});
	}

	function hidePrevProjects(index) {
		while (index > 0) {
			index--;
			const project = projects[index];

			if (ScrollTrigger.isInViewport(project.el, 0.1, true)) {
				project.hide(-1);
			} else {
				break;
			}
		}
	}

	function hideNextProjects(index) {
		while (index < projects.length - 1) {
			index++;
			const project = projects[index];

			if (ScrollTrigger.isInViewport(project.el, 0.1, true)) {
				project.hide(1);
			} else {
				break;
			}
		}
	}

	function deselectVideo() {
		projects[prevSelected].deselect().then(() => {
			$scrollSmoother.paused(false);
			scrollLocked.set(false);
		});

		for (let i = 0; i < projects.length; i++) {
			if (prevSelected !== i) projects[i].show();
		}

		prevSelected = null;
	}

	function project_binding($$value, i) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			projects[i] = $$value;
			$$invalidate(0, projects);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$selectedProject*/ 2) {
			$: handleSelectedProject($selectedProject);
		}
	};

	return [projects, $selectedProject, project_binding];
}

class Projects extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Projects;