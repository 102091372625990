import './About.svelte.css';
/* src/lib/components/about/About.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "../../../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div1;

	return {
		c() {
			div1 = element("div");

			div1.innerHTML = `<div class="content svelte-nypjyp" id="about-content"><p class="svelte-nypjyp">A hands-on filmmaker, Rodrigo&#39;s unwavering passion for film is evident in
			the unique insight he brings to each stage of the filmmaking process -
			writing, directing &amp; editing - his unparalleled ability to clearly see
			through an idea from its inception to screen.<br/><br/>Rodrigo has collaborated
			with some of the world&#39;s biggest brands including AB-Inbev, Coca-Cola,
			Mondelez &amp; Universal Music.</p> 

		<p class="svelte-nypjyp">With a background in Executive Production, Rodrigo perfectly understands
			the set and how to create films with perfect craft in time and
			budget.<br/><br/>Rodrigo&#39;s work in advertising has been nominated by, and has won,
			a plethora of industry leading awards including at the Effies, D&amp;AD’s, One
			Show, CC &amp; Clio.</p> 

		<a href="mailto:hello@robonilla.com" class="email svelte-nypjyp">hello@robonilla.com</a> 

		<ul class="svelte-nypjyp"><li class="svelte-nypjyp"><a href="https://www.instagram.com/robonilla/" target="_blank" class="svelte-nypjyp">Instagram</a></li> 


			<li class="svelte-nypjyp"><a href="https://vimeo.com/robonilla" target="_blank" class="svelte-nypjyp">Vimeo</a></li></ul></div>`;

			attr(div1, "class", "about svelte-nypjyp");
			attr(div1, "id", "about-container");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
		}
	};
}

class About extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default About;