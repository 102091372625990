import './MobileVideo.svelte.css';
/* src/lib/mobile/MobileVideo.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	src_url_equal,
	text
} from "../../../_snowpack/pkg/svelte/internal.js";

import { getImagePath } from '../stores/index.js';

function create_fragment(ctx) {
	let div3;
	let video_1;
	let source;
	let source_src_value;
	let t0;
	let div2;
	let div0;
	let p0;
	let t1;
	let t2_value = /*index*/ ctx[0] + 1 + "";
	let t2;
	let t3;
	let div1;
	let p1;
	let t4;
	let t5;
	let p2;
	let t6;

	return {
		c() {
			div3 = element("div");
			video_1 = element("video");
			source = element("source");
			t0 = space();
			div2 = element("div");
			div0 = element("div");
			p0 = element("p");
			t1 = text(".");
			t2 = text(t2_value);
			t3 = space();
			div1 = element("div");
			p1 = element("p");
			t4 = text(/*name*/ ctx[1]);
			t5 = space();
			p2 = element("p");
			t6 = text(/*date*/ ctx[2]);
			if (!src_url_equal(source.src, source_src_value = /*videoSource*/ ctx[4])) attr(source, "src", source_src_value);
			attr(source, "type", "video/mp4");
			attr(video_1, "class", "video svelte-lf2d6j");
			video_1.controls = true;
			attr(video_1, "poster", /*imageSource*/ ctx[3]);
			attr(div0, "class", "index svelte-lf2d6j");
			attr(p2, "class", "date svelte-lf2d6j");
			attr(div1, "class", "info svelte-lf2d6j");
			attr(div2, "class", "content svelte-lf2d6j");
			attr(div3, "class", "project svelte-lf2d6j");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, video_1);
			append(video_1, source);
			append(div3, t0);
			append(div3, div2);
			append(div2, div0);
			append(div0, p0);
			append(p0, t1);
			append(p0, t2);
			append(div2, t3);
			append(div2, div1);
			append(div1, p1);
			append(p1, t4);
			append(div1, t5);
			append(div1, p2);
			append(p2, t6);
		},
		p(ctx, [dirty]) {
			if (dirty & /*index*/ 1 && t2_value !== (t2_value = /*index*/ ctx[0] + 1 + "")) set_data(t2, t2_value);
			if (dirty & /*name*/ 2) set_data(t4, /*name*/ ctx[1]);
			if (dirty & /*date*/ 4) set_data(t6, /*date*/ ctx[2]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div3);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { video } = $$props;
	let { image } = $$props;
	let { index } = $$props;
	let { name } = $$props;
	let { date } = $$props;
	let imageSource = getImagePath(image);
	let videoSource = `/assets/videos/${video}.mp4`;

	$$self.$$set = $$props => {
		if ('video' in $$props) $$invalidate(5, video = $$props.video);
		if ('image' in $$props) $$invalidate(6, image = $$props.image);
		if ('index' in $$props) $$invalidate(0, index = $$props.index);
		if ('name' in $$props) $$invalidate(1, name = $$props.name);
		if ('date' in $$props) $$invalidate(2, date = $$props.date);
	};

	return [index, name, date, imageSource, videoSource, video, image];
}

class MobileVideo extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			video: 5,
			image: 6,
			index: 0,
			name: 1,
			date: 2
		});
	}
}

export default MobileVideo;