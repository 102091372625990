function E() {
	// Keep this empty so it's easier to inherit from
	// (via https://github.com/lipsmack from https://github.com/scottcorgan/tiny-emitter/issues/3)
}

E.prototype = {
	on: function (name, callback, ctx) {
		let e = this.e || (this.e = {});

		(e[name] || (e[name] = [])).push({
			fn: callback,
			ctx: ctx
		});

		return this;
	},

	once: function (name, callback, ctx) {
		let self = this;

		function listener() {
			self.off(name, listener);
			callback.apply(ctx, arguments);
		}

		listener._ = callback;
		return this.on(name, listener, ctx);
	},

	emit: function (name) {
		const data = [].slice.call(arguments, 1);
		const evtArr = ((this.e || (this.e = {}))[name] || []).slice();
		let i = 0;
		const len = evtArr.length;

		for (i; i < len; i++) {
			evtArr[i].fn.apply(evtArr[i].ctx, data);
		}

		return this;
	},

	off: function (name, callback) {
		const e = this.e || (this.e = {});
		const evts = e[name];
		const liveEvents = [];

		if (evts && callback) {
			let i = 0,
				len = evts.length;
			for (; i < len; i++) {
				if (evts[i].fn !== callback && evts[i].fn._ !== callback) liveEvents.push(evts[i]);
			}
		}

		// Remove event from queue to prevent memory leak
		// Suggested by https://github.com/lazd
		// Ref: https://github.com/scottcorgan/tiny-emitter/commit/c6ebfaa9bc973b33d110a84a307742b7cf94c953#commitcomment-5024910

		liveEvents.length ? (e[name] = liveEvents) : delete e[name];

		return this;
	}
};

const Emitter = new E();

export default Emitter;
